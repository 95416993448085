<template>
  <div class="maindiv">      
    <div class="textsection">      
      <div class="section">         
        <img class="nirav" src="../assets/Nirav at museum.jpg" alt="The curator Nirav">

        <br> &nbsp; <br> 
        
        <h3 class="sm-title">The History of the Museum</h3><br>
        <p>
          Hello. I'm Nirav, currator of Rock Cliff Museum. I started playing in 2016 on Chaos. I know that isn’t advised, but a friend who had played Wurm for years wanted to try Chaos just to see what it looked like – not to join a Kingdom or anything. She warned me that starting Wurm there would be extremely difficult, and made it very clear that it was not a typical beginning point, but I was like “How bad can it be?  It’s just a game, yeah?” She made a new toon, and I entered Wurm through Gold Coast. Within 24 hours I had two eagle spirits in my noob tent, and 21 of my corpses scattered all over the hillside, and I had hardly slept. I was babbling in GL about what I was doing, where I was, and folks were spending hours with me in PMs trying to explain things while my friend was asleep. Two in particular were incredibly kind and spent long hours with me that first week - Waarokku and Cthululu. Ah, Waar. Your name will ever conjure a smile from me, and tears in my eyes. But it was this time, and the time given to me by so many others, that made me realize this game, and the people in it, were different. That this world had beauty in it beyond the pixels.
        </p>
        <p>
          Things went about as well as you all would expect, and two weeks later, my friend and I ran from Chaos to the new server Xanadu.  I think Xan was harder.  The run back to the dead corpses for sure was longer. And the mobs were way more numerous. But there was no longer a lone starter town surrounded by MR towers and enemies.  There were bridges and massive estates and roads and unfathomable things people had done to the land. And always, people helped, talked, said hi with enthusiasm, sent gifts of pelts and tools, and each of them said, “Just do something for others when you have gotten to where I am and can do that.”
        </p>
        <p>
          We left Xan two weeks later to go to Independence where she had played for years. I still remember sailing through Crystal Canal in the middle of the night.  Oh, the colors! The lights and all their colors!  And the banners! And then that open sea just as you come out the other side, with all those lights reflecting on the water, all those deeds, all those people! 
        </p>
        <p>
          A week later, I wanted my own deed.  And the thing in the game that was starting to make me sad got more intense. What was that? The fallen deeds. The ones who left. The people that stories were still being told about, but were gone. It was a game, but these people all had a part to play in the creation of an entire little world, and they mattered, and all that was left for me to see of them was piles of stuff lying around with signatures on them. I was really saddened by all these abandoned places, as if I had somehow missed the creation of the world. To me, they were magic names that conjured only guesses as to who all these people were. I completely by accident ended up choosing to build my first deed at Tich’s old deed on Inde, and was humbled and felt a kind of responsibility to make sure I did something useful there. Months later, when I got my bearings and had built my first little house there, I promised myself and everyone in the game that I would someday make something there that fulfilled my promise to give back when I could. It would be a while before I knew what that was.
        </p>
        <p>
          I continued to explore, that being my favorite thing to do in the game. I originally wanted to stand on every tile on every server and was appalled and indignant when I realized that some folks fenced their deeds and kept me from my goal lol. But as I attempted to accomplish that impossible dream, I got myself to most areas of Inde and half of Xan, and in the process, ran into many abandoned places, always causing the same feelings of nostalgia in me. I would pick up a relic here and there – sometimes a barrel, other times a tool, and put them in my cart, cherishing each signature, wondering who these people who vanished were. I rarely found anything technically valuable for resale, but that really wasn’t my purpose anyway. My goal was to save these things with these magic names, but it was a couple more years before I came up with the idea of a museum. I became a casual looter, because while I had no intention of making money reselling things that weren’t mine, I definitely want to keep things safe so people can see them and remember who was here. I also picked up a ton of mats that I have given to impalongs in hopes those who left would be pleased by that.
        </p>
        <p>
          The first museum I built was on Lyric Beach, my main deed, and was fairly small, and very nuanced. Full of my own feelings of nostalgia, the things in it were things with signatures, but also things friends gave me, or things I was irrationally emotionally attached to, and while it was a neat place, it wasn’t yet coherent. Over time, realizing the wealth of things with signatures that I had gathered over the years, it became very obvious that I would need a bigger place, and a less esoteric presentation. Hvergi decided about that time to move to a different area and left his smithing deed that he had been at for 10 years to move to the coast and build a massive estate. He gave me the smithy, and I turned it into Rockcliff Museum with his blessing. 
        </p>
        <p>
          The smithy is old, and was occupied before Hvergi by Dottie and frequented by Tich. There are still things from both of those people there, which have been moved to the museum for permanent safekeeping. Tich’s cart was given to Enki to place at her memorial on Release. Tich’s bow, which was lying on the ground when I created my deed on top of where hers used to be, is also in the museum. It is my hope that what I have done honors the lands I found myself inheriting as well as all of you in this game. 
        </p>
        <p>
          When I got the idea for the big museum, the work began, slowly, and I had help. Those who helped are immortalized on a plague on the Museum itself. I’m not a builder, terrible at grinding, so I had to stop exploring at times and work on things that would allow me to create something big enough. I also have my own challenges irl, and got called away from the game and this project many times.  So there has been a lot of hype about it for quite a while, and it does not look like something that took two years to create. However, the work identifying exact signatures and looking up whether these folks were on Chaos or PvE has been quite significant. I have learned through this process how unfathomable the amount of work involved in impalongs is. I am in profound awe of folks who are that organized.
        </p>
      </div> 

      <div class="section"> 
        <h3 class="sm-title">Future Plans for Rockcliff Museum</h3><br>
        <p>
          The ultimate goal was for this to become a Heritage Site. That is not going to happen, because this 
          is a deed that is constantly changing, and a Heritage Site stops all production and solidifies things 
          as they are in a moment of time, that can’t be changed in the future. The GMs and I have discussed 
          options, and as long as someone is here moving things, adding things, there really isn’t anything 
          that can be done to make this deed different in the ‘legal’ sense. Instead, I have committed myself 
          entirely to running this as Your place. Nothing here will ever be used by me personally in any way, 
          unless it is one of the wagons I personally own that are on-loan here from me. I take my own honor and 
          integrity very seriously, and will do my best to do right by all of you and your donations, as well as 
          the items I find myself and put here.
        </p>
        <p>
          The Museum and Museum Grounds have been expanded to include the new Hall of Alliances building, and 
          Camaril and I are working to raise a massive terrace for the new NFI building that will be finished in 
          2025. That building will be designed by Rayen on NFI. The Museum received an anonymous donation of 50k 
          dirt for the terrace.
        </p>
        <p>
          Yearly Gala - The Museum will have a Gala each year around the time of the Grand Opening. This Gala is 
          intended to be a relaxing event where folks can wander the buildings and grounds, remember their lives 
          and their friends in Wurm, tell stories, and celebrate everything we have created together in Wurm. It 
          is also an opportunity to wear your favorite outfits, for there is no danger on the Museum grounds. 
          Creatures are kept from visitors by locked gates. The GMs might appear and change people into various 
          fantastical things, but that is the only thing that can happen to a player here. 
        </p>
      </div>

      <div class="section"> 
        <h3 class="sm-title">How it Started</h3>
        <p>
          <img class="slightly-larger" src="../assets/museum concept.jpg" alt="museum concept">  &nbsp;  &nbsp; 
          <img class="standard-size" src="../assets/obs.jpg" alt="observation tower concept"> <br>
          The two above images were my original concepts. The final versions did not vary much.
        </p>
        <br> &nbsp; <br> 
        <p>
          <img class="slightly-larger" src="../assets/empty museum.jpg" alt="empty museum"> <br>
          The museum just after it was built.
        </p>
        <br> &nbsp; <br> 
        <p>
          <img class="slightly-larger" src="../assets/observation tower.png" alt="observation tower"> <br>
          Observation tower during Ritual of the Sun.
        </p>
      </div>

      <br> &nbsp; <br> 
    </div>
  </div>  
</template>


<script>
  export default {
    name: 'MuseumHistory'
  }
</script>


<style scopped>
.nirav {
  width: 400px;
}

</style>