
<template>
  <div class="maindiv">
    <div class="textsection">
      <h1>Special Permanent Exhibits</h1>
      
      <div class="section"> 
        <h3 class="sm-title">The Wurm Memorial Display</h3><br>
        This installation is in honor of all who have left us. The Legacy Coffin and Flowers were donated by 
        Pomeray. Because I do not think those who made the flowers have passed, I did not feel it appropriate 
        to put their names on the flowers. With the flowers nameless, everyone could see the display as relevant 
        to those they have personally lost. The names of the folks who made the flowers are here instead. Pomeray, 
        Lono, Tegel, Shaka, Ona, Moru. Sidereal has donated rare source crystals for inside the coffin. The names 
        of everyone that I am aware of who has gone beyond are written on a source crystal. Feel free to open the 
        coffin and read the names.
      </div>      
      
      <div class="section"> 
        <h3 class="sm-title">The God Statues</h3><br>
        I decided to leave the names blank on these so the Gods stood as themselves. Elentari made the Libila and 
        the Fo, and both were donated to the museum by Sadsack. Nirav made and donated the Magranon and the Vynora. 
        Nalimar arranged the displays by the statues and Hvergi sent each of his priests to finish the altars. 
        Nalimar’s beautiful arrangements have really made this display fantastic.
      </div>  
      
      <div class="section"> 
        <h3 class="sm-title">The Conference of Goblin GMs</h3><br>
        This display is a humorous art piece meant to honor the GMs and poke fun all at the same time. The GMs are all in heavy discussions with each other with Chat moderators standing by, and Enki and Pomona supervising. I tried to get the GMs to tell me what their favorite colors were, but that went about as well as anyone would expect with them not being aware as to why they were asked. One of the GMs was actually called during the painting of this display to help, and Nirav had to tote the unruly goblin offdeed to have the GM help without seeing the installation they were helping with. During this process we learned that goblins with runes that cause them to light up cannot be painted. The chat moderators are the two goblins with lights that refused to take paint. It is my hope that this display will amuse the staff as well as thank them for all the work they do. 
      </div> 
      
      <div class="section">  
        <h3 class="sm-title">Pride Flag Set</h3><br>
        Shydow was inspired very recently in the summer of 2022 to create inclusive flags for folks to display.  
        His Pride Flag sets were well researched and painted in brilliant colors, and are a beautiful testament to 
        the acceptance in Wurm of everyone. The Pride Flag Set is at the top of the Rockcliff Museum South Entrance 
        Arch, the roof of which can be accessed via either of the side doors. </p>
      </div>  

      <div class="section">  
        <h3 class="sm-title">Care For One Another - by Stanlee</h3><br>
        I asked Stanlee to submit something, and encouraged him to come up  with a tacklebox art installation.  
        I honestly could never have imagined that he would submit something so incredibly beautiful, simple, and elegant. 
        When it was in place, and I looked at it, I was moved to tears. It is a small piece in the front of the museum on 
        the main floor.
      </div>

      <div class="section"> 
        <h3 class="sm-title">Aaedean’s Memorial – Written by KindraShae and Nirav</h3><br>
        Aaedean, aka Terry, was an amazing person. Known by many for his helpful nature and kind heart, I don't think 
        I've ever heard a single person who had a bad thing to say about him. <br>
        Aaedean was an epic Wurmian. He had amazing skills in so many areas from his countless hours of grinding, though he 
        didn't consider it that. I only wish he had uploaded them into Wurmnode. He could often be found in front of his 
        always-burning forges or in the depths of a mine, creating mortar, but mostly helping others. I'm pretty sure that 
        many new players have stuck with the game after receiving a helping hand from him. Keina has erected a shrine for 
        him at his deed, White Castle (Xanadu I-26) and his collection of shinies (rares/supremes) is a sight to see. The 
        deed will be maintained in his memory. People are welcome to drop by and leave a token of their regards, or the 
        corpse of his nemesis, the seal. He will be sorely missed by his many friends in the game, and by his alliance, the 
        Free People of the Blue Banners. <br>
        Aaedean imped over 2,000 items for Rockcliff Museum over the course of two years, mostly to find signatures. His 
        imping uncovered hundreds of signatures of people who are now represented here because of his time and effort. 
        In 2023, he was part of a very small crew of smiths who imped everything in the Museum that was there at that time 
        to make sure it was at least 60ql. Rockcliff Museum, and all of Wurm, is better for having had Aaedean’s dedication, 
        and this is why he has a personal Memorial here. It is on the top floor of the Hall of Alliances.
      </div>
      
      <br> &nbsp; <br> 
    </div> 
  </div>  
</template>


<script>
  export default {
    name: 'SpecialPermanentExhibits'
  }
</script>


<style scoped>
 


</style>