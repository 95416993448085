<template>
  <div class="maindiv">
    <div class="topbit links">
      <div @click="backtoStories" v-if="haiku">Back</div>
    </div>
    
    <StoryPage1 v-if="page1" :section="section" />
    <HaikuContest v-if="haiku" />

    <div class="links">
      <div @click="gotoHaiku" v-if="!haiku">Haiku Contest</div>
    </div>
    
    <br> &nbsp; <br> 
  </div>  
</template>


<script>
  import StoryPage1 from "./StoryPage1.vue"
  import HaikuContest from "./HaikuContest.vue"
  export default {
    name: 'MuseumStories',
    components: {
      StoryPage1,
      HaikuContest
    },
    data() {
      return {
        page1: true,
        haiku: false
      }
    },   
    props: ['section'],
    methods: {
      gotoHaiku() {
        this.haiku = true;
        this.page1 = false;
      },

      backtoStories() {
        this.haiku = false;
        this.page1 = true;
      }
    }
  }
</script>


<style scopped>
  .links {    
    width: 96vw;
    margin-left: 2vw;
    color: green;
    font-weight: bold;
    cursor: pointer;
  }
  .topbit {    
    margin-top: 20px;
  }
</style>