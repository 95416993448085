<template>
  <div class="maindiv">  
    <div class="textsection">
      <h1>Museum Guide</h1>      
      
      <div class="section howto">
        <h3 class="how-title">If You Read Only One Thing On This Website, Please Read This:</h3><br>
        The point of Rockcliff Museum is to showcase not the items, but the names of the people who made this
        world. This Museum is about all of you. Each item is renamed with the name of the person who created
        it. Please do not right-click examine the items when visiting. Every time an item is examined, there is a
        chance the signature will fade forever, and yours will replace it. I want to avoid that scenario as much as
        I can, so please keep actual examination to a minimum. For a more detailed explanation of this, please
        read The Preservation Process page in full. Thank you!
      </div>
            
      <div class="centerthis">
      <img class="enterimage" src="../assets/north museum entrance.jpg" alt="North museum entrance at night"><br>
      North Entry Hall and Tich Memorial Bridge
      </div>
            
      <div class="section"> 
        <h3 class="sm-title">Rockcliff Museum Building</h3><br>
        The main Museum building has 3 floors. The first floor is full of items found or donated by folks all 
        over the PvE servers in the Southern Freedom Islands. The second floor is full of items found or donated 
        by folks on Chaos, the PvP server, as well as Epic PvPers who came to Freedom to leave their footprint here, 
        too. The third and topmost floor is entirely dedicated to the people that work day and night to make sure 
        we can all be here doing this together. This third floor will always be a place of honoring the Devs and 
        Staff and Gods in Wurm.
      </div> 
            
      <div class="section"> 
        <h3 class="sm-title">Observation Tower</h3><br>
        The Observation Tower houses the Room of Anvils, Nalimar’s Token display, Dyes by Auntie Sid, and there 
        are other displays on each floor. Access to the Observation Tower is around the side. You will find a ladder 
        up, and every floor except the ground floor of that building is open to all, and each floor has small displays 
        for folks to enjoy. The view at the top is a breathtaking vista of the entire southern half of the Inner Sea 
        as well as a joyful display by Cenotaph. 
      </div> 
      
      <div class="section"> 
        <h3 class="sm-title">Museum Shop</h3><br>
        This Shop is down at the intersection of FM NW HWY coming from Freedom
        Market, and the Coast Highway that goes along the shore. It is in Lyric Beach which is right down the hill
        next to Rockcliff Museum by the peat bog. At the shop you will find archaeology items, recipe and
        archaeology mob drops from Nirav, leather and cloth items made by Camaril, dyes made by Auntie
        Sidereal, and tools and weapons made by Hvergi.
      </div> 

      <div class="section"> 
        <h3 class="sm-title">Hall of Alliances</h3><br>
        The Hall of Alliances was created to showcase various communities in Wurm. Alliances and other community 
        groups are invited to do a one tile display with items that have signatures from their community mates, 
        so everyone can be together in the Hall. This is open to current and past alliances.
        Like the main Museum building, there is a second floor for Kingdoms found on Chaos. Over 20 Kingdoms, past 
        and present, are currently represented in the Hall with displays of items with signatures of players who 
        were/are in those Kingdoms.
        Nirav and Camaril terraformed an area north of the Museum grounds to build this new building prior to the 
        Gala in 2023. There is still room for more displays. <br>
        If your alliance or kingdom is interested in creating a one tile display and having it installed here, 
        please contact Nirav on the <a href="https://forum.wurmonline.com/index.php?/profile/76620-nirav/">
        Wurm forum</a> or in game.
      </div> 

      <div class="section">
      <img class="standard-size" src="../assets/hall of alliances front far.jpg" alt="hall of alliances front from a distance"> &nbsp; 
      <img class="standard-size" src="../assets/hall of alliances 1st floor.jpg" alt="hall of alliances interior 1st floor"> &nbsp; 
      <img class="standard-size" src="../assets/hall of alliances 2nd floor.jpg" alt="hall of alliances interior 2nd floor"> <br>
      These three pictures are the hall of alliances as viewed from the second floor of the main museum building,
      the first floor with PVE alliance displays, and the second floor PVP kingdom displays.
      </div>

      
      <div class="section"> 
        <h3 class="sm-title">Hours of Operation</h3><br>
        Rockcliff Museum is open to the public 24 hours a day, 7 days a week except for 4 days prior to the yearly Gala in Sept.
      </div> 
      
      <div class="section"> 
        <h3 class="sm-title">Location</h3><br>
        Rockcliff Museum is located down the Freedom Market NW Highway, a rough couple dozen tiles or so 
        from Rockcliff Cathedral, on the E side or the road. It is nestled in a corner of the base of Dragon 
        Fang Mountain, and has a stunning view of the sea and Black Dog Island. It is built upon the lands 
        that Tich settled when Independence opened. It is located at K 15, Independence, SFI. You may portal 
        to The Howl and then run down the FM road to the Museum. Please look at the two diagrams here for more 
        specific location information.<br>
        <img class="standard-size" src="../assets/albia indy map for website.png" alt="albia indy map for website"> 
        <img class="standard-size" src="../assets/museum map.png" alt="map">
      </div> 
      
      <div class="section"> 
        <h3 class="sm-title">Where to Stay</h3><br>
        Fairview Harbor Inn is at the docks down the peat hillside from the museum in Oceania Terraces at Fairview. 
        The museum can be seen from this Inn. There are two buildings. Building 1 has 18 rooms and Building 2 has 8 
        rooms. All rooms at the Inn are free. During the Gala, it is recommended that you reserve a room. Maple Leaf 
        Inn on the far western edge of Lyric Beach is a guesthouse with 4 free beds that is also always open to the 
        public. It is across from the original museum site that now houses special items in Nirav's personal 
        collection. There is also a camping area between Rockcliff Museum and Oceania Terraces at Fairview where anyone 
        can pitch a tent during the Gala. This camping area is clearly marked.
      </div> 
      
      <div class="section"> 
        <h3 class="sm-title">Fees</h3><br>
        Rockcliff Museum is FREE and always will be as long as I have any control over it. If you wish to add coins to the token, you may do so, but are under no obligation whatsoever. If at any time, the museum has 5 years stored, there will be a sign asking that no one contribute until the deed storage is below that time threshold. This will keep it from accumulating ludicrous amounts of money.
      </div>  
      
      <div class="section"> 
        <h3 class="sm-title">Surrounding Area</h3><br>
        Fanghenge and the beautiful Rockcliff Cathedral built by Tich are just across the road from Rockcliff Museum, and well worth the time to go see. For the more adventurous, continue south up the NW FM Highway to access Dragon Fang Mountain’s Green Ring.
      </div> 
      
      <br> &nbsp; <br> 
    </div>
  </div>  
</template>


<script>
  export default {
    name: 'MuseumGuide',
    methods: {
    }
  }
</script>


<style>
  .enterimage {
    width: 94%;
    max-width: 800px;
    border: 2px solid black;
    margin: 30px auto 10px auto;
  }
  
</style>