<template>
  <div class="maindiv">  
    <div class="textsection">
      <h1>Hall of Alliances</h1>      
      <p>
        The Hall of Alliances was created to showcase various communities in Wurm. Alliances and other community groups are 
        invited to do a one tile display with items that have signatures from their community mates, so everyone can 
        be together in the Hall. This is open to current and past alliances.<br>
        Like the main Museum building, there is a second floor for Kingdoms found on Chaos. 20 Kingdoms past
        and present are currently represented in the Hall with displays of items with signatures of players who
        were/are in those Kingdoms.<br>
        Nirav and Camaril terraformed an area north of the Museum grounds to build this new building. It will
        be fully completed and filled with displays in time for the Gala Sept 3rd, 2023. There is still room for more
        displays. If your alliance or kingdom is interested, please contact Nirav on the wurm forum or in game.
      </p>
      <div class="images">
          <img class="size" src="../assets/hall of alliances.jpg" alt="Huge white marble building lit with pink lamps"> <br>
          Hall of Alliances
      </div>

      <div class="alliances">
        <h3>First Floor Displays, PVE</h3>
        <p><b>POP The Magic Dragon</b>, Independence, Dec 2012 to Nov 2016<br>
        A village with around 20 players. 
        <span class="link" @click="$emit('chpage', 'stories', 'pop')">See Story</span> </p>

        <p><b>Shepherds of Gnome Alliance</b>, Independence</p>

        <p><b>Port Benden Alliance</b>, Celebration</p>

        <p><b>Elysian Academy Alliance</b>, Pristine, Dec 2012 to Current<br>
          We are a combination of Elysian Fields started by Sidereal Dec 13th 2012 and Academy Alliance started
          by Explora or LadyGodiva about the same time.</p>

        <p><b>NEXA Alliance</b>, Xanadu, 2015 to Current</p>

        <p><b>Echoes of Silence Alliance</b>, Pristine, Nov 2013 to Current</p>

        <p><b>Silent Hill Alliance</b>, Independence, A Very Long Time ago to Current <br>
        <span class="link" @click="$emit('chpage', 'stories', 'silent')">See Story</span> </p>        

        <p><b>ALBIA Allliance</b>, Independence, 2014 to Current</p>

        <p><b>Identity Crisis</b>, Independence, 2022 to Current</p>
        <p><b>The Blue Banners</b>, Zanadu, May 19, 2021 to Current</p> 

        <p>&nbsp;</p>
        <h3>Second Floor Displays, PVP</h3>
        <p>The Kingdoms represented here in the Hall are:</p>
        
        <p><b>Template MR</b><br>
        <b>Template JK</b><br>
        <b>Template HOTS</b><br>
        <b>PMK MR</b><br>
        <b>PMK JK</b><br>
        </p>
        
        <p>
        <b>Black Legion</b><br>
        <b>Ebonaura</b><br>
        <b>Macedon</b><br>
        <b>Dreadnaught Dynasty</b><br>
        <b>The Crusaders</b><br>
        <b>Wurm University</b><br>
        <b>Valhall Descendants</b><br>
        <b>Pandemonium</b><br>
        <b>Legion of Anubis</b><br>
        <b>Apocalypse Order</b><br>
        <b>The Spartan Empire</b><br>
        <b>Heroic Libila Legion</b><br>
        <b>Pirates</b><br>
        <b>Knights of Valrei</b><br>
        <b>Cheese</b><br>
        <b>Kenn-Jellon</b><br>
        <b>Capybara</b><br>
        <b>Libila's Dominion</b><br>
        <b>Hot Dog</b></p>
      </div>
        
      <div class="images">
        <div>          
          <img class="size" src="../assets/front of hall of alliances.jpg" alt="A large entrance under a huge arch"> <br>
          Front of Hall of Alliances
        </div>
        <div>
          <img class="size" src="../assets/work in prog.jpeg" alt="museum aerial view with two diggers working on expansion"> <br>
          Aerial view of Camaril and Nirav terraforming the area for the new building<br>
        </div>
      </div>

      <p>&nbsp;</p>
      
      
    </div>
  </div>  
</template>


<script>
  export default {
    name: 'AlliancesAndVillages',
    emits: ["chpage"]
  }
</script>


<style scopped>
  h1 {
    display: block;
    width: fit-content;
    margin: 40px auto 40px auto;
  }

  h3 {
    font-size: 1.4em;
    margin: 0;
    color: rgb(33, 72, 155);
  }

  .images {
    width: fit-content;    
    margin: auto;
  }
  img {
    margin: auto;
    margin-top: 40px;
  }
  .size {
    width: 600px;
    border: 2px solid black;
  }

  .alliances {
    display: block;
    margin: auto;
    margin-top: 40px;
    border: 1px solid orange;
    padding: 10px;
  }
  .link {
    font-style: italic;
    color: rgb(30, 87, 209);
    cursor: pointer;
  }
</style>