<template>
  <div id="app">
    <div class="topbar">

      <div class="mobileheader">  
        <div class="left">              
          <button class="hamburger" @click="open()">&Congruent;</button>
        </div>           
        <h1 class="topcentered">Rockcliff Museum</h1>
      </div>

      <div class="largeheader">
        <h1 class="topcentered">Rockcliff Museum</h1>
        <div class="navigation">
          <a class="navlink" @click="switched('home')">Home</a>
          <a class="navlink" @click="switched('opening')">2024 Gala</a>       
          <a class="navlink" @click="switched('history')">History</a>
          <a class="navlink" @click="switched('guide')">Museum Guide</a>
          <a class="navlink" @click="switched('village')">Hall of Alliances</a>
          <a class="navlink" @click="switched('names')">Signatures</a>      
          <a class="navlink" @click="switched('process')">Preservation Process</a>
          <a class="navlink" @click="switched('stories')">Stories</a>
          <a class="navlink" @click="switched('exhibits')">Special Permanent Exhibits</a>
        </div> 
      </div>
      
      <div class="expand" v-if="expanded">
        <a class="smlink" @click="switched('home')">Home</a><br>
        <a class="smlink" @click="switched('opening')">2024 Gala</a><br>
        <a class="smlink" @click="switched('history')">History</a><br>
        <a class="smlink" @click="switched('guide')">Museum Guide</a><br>
        <a class="smlink" @click="switched('village')">Hall of Alliances</a><br>
        <a class="smlink" @click="switched('names')">Signatures</a> <br>     
        <a class="smlink" @click="switched('process')">Preservation Process</a><br>
        <a class="smlink" @click="switched('stories')">Stories</a><br>
        <a class="smlink" @click="switched('exhibits')">Special Permanent Exhibits</a><br>
      </div>
       
    </div>

    <div>
      <MuseumHome v-if="page=='home'" />
      <MuseumHistory v-else-if="page=='history'" />
      <MuseumGuide v-else-if="page=='guide'" />
      <AlliancesAndVillages v-else-if="page=='village'" @chpage="changePage" />
      <MuseumNames v-else-if="page=='names'" />
      <PreservationProcess v-else-if="page=='process'" />
      <MuseumStories v-else-if="page=='stories'" :section="sendSection" />
      <SpecialPermanentExhibits v-else-if="page=='exhibits'" />
      <Gala v-else-if="page=='opening'" />
    </div>
    
  </div>  
</template>


<script>
import MuseumHome from './components/MuseumHome.vue'
import MuseumHistory from './components/MuseumHistory.vue'
import MuseumGuide from './components/MuseumGuide.vue'
import MuseumNames from './components/MuseumNames.vue'
import PreservationProcess from './components/PreservationProcess.vue'
import MuseumStories from './components/MuseumStories.vue'
import SpecialPermanentExhibits from './components/SpecialPermanentExhibits.vue'
import Gala from './components/events/Gala2024.vue' 
import AlliancesAndVillages from './components/AlliancesAndVillages.vue' 

export default {
  name: 'App',
  components: {
    MuseumHome,
    MuseumHistory,
    MuseumGuide,
    MuseumNames,
    PreservationProcess,
    MuseumStories,
    SpecialPermanentExhibits,
    Gala,
    AlliancesAndVillages
  },
  data() {
    return {
      page: 'home',
      expanded: false,
      sendSection: null
    }
  },
  methods: {
    open() {
      this.expanded = !this.expanded
    },
    switched(newpage) {
      this.page = newpage
      this.expanded = false
    },
    changePage(v1, v2) {
      console.log(v1, v2);
      this.page = v1;
      this.sendSection = v2;
    }
  }
}
</script>

<style>
* {    
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: 0;
}

img {
  max-width: 100%;
}

.page {
  width: 96vw;
  margin-left: 2vw;
}

.topbar {
  width: 100vw;
  height: 90px;
  margin: 0;
  background-color: black;
  color: white;
  border: 1px solid black;
}
.left {
  width: fit-content;
  float: left;
  margin-left: 10px;
}

.topcentered {
  display: block;
  width: fit-content;
  margin: auto;  
  margin-top: 20px;
  margin-bottom: 10px;
}

.sm-title {
  display: inline-block;
  margin: 0;
  color: rgb(33, 72, 155);
}

.wide {
  width: 100%;
}

.standard-size {
  max-width: 400px;
  border: 2px solid black;
  margin: 10px;
}

.slightly-larger {  
  width: 700px;
  border: 2px solid black;
  margin: 10px;
}

.textsection {
  width: 90%;
  margin: auto;
}

.centered {    
  display: block;
  margin: auto;  
}

.text-centered {
  text-align: center;
}

.maindiv {
  width: calc(100vw - 5px);
  height: calc(100vh - 120px);
  overflow: scroll;
  scrollbar-color: #555555 #bbbbbb;
  scrollbar-width: thin;
}

.howto {
  color: #ee0000;
  font-size: 1.2em;
}
.how-title {  
  display: inline-block;
  margin: 0;
  color: #ee0000;
}


.hamburger {
  background-color: black;
  color: white;
  font-size: 1.5em;
  border: 0;
}

.navigation {
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.expand {
  position: absolute;
  top: 100px;
  left: 10px;
  background-color: black;
  border: 1px solid white;
  padding: 10px;
  cursor: pointer;
  line-height: 2em;
}

.section {
  margin-top: 30px;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #bbbbbb;
}
::-webkit-scrollbar-thumb {
  background: #555555;
}


a {
  color: rgb(0, 112, 177);  
  text-decoration: none;
}
a:hover {
  color: rgb(0, 162, 255);
}

.navlink {
  margin: 0 20px 0 20px;
}


@media only screen and (min-width : 10px) {
  .mobileheader {
    display: block;
  }

  .largeheader {
    display: none;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1000px) {
  .mobileheader {
    display: none;
  }
  .topbar {    
    height: 120px;
  }
  .largeheader {
    display: block;
  }
}

</style>
