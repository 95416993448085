<template>
  <div class="maindiv">
    <img class="wide" src="../assets/museumfront-short.jpg" alt="Front of museum with statue of horse and lava fiend">
    <div class="textsection">
      <div class="welcome">
        <h3>
            Welcome Everyone to Rockcliff Museum, a place where what is being honored and revered is You. This is where your stories and your memories matter more than any items could ever be worth. 
        </h3>
        <h3>
            This museum exists in a game called Wurm Online. It is a huge project with a lot of history, so please take your time perusing this site and all the pages before visiting, so your experience is informed and you get the most out of being there. 
          </h3>
          <h3>
            This is a fansite for the game, and not associated in any other way with the game itself. There is a disclaimer about that at the bottom of this page. 
        </h3>
      </div>

      <div class="discord"><h3 class="sm-title">If you visit, sign the Guestbook if you feel inspired at https://discord.gg/paFQNNdcmx</h3></div>
      
        
      <div class="section howto">
        <h3 class="how-title">If You Read Only One Thing On This Website, Please Read This:</h3><br>
        The point of Rockcliff Museum is to showcase not the items, but the names of the people who made this
        world. This Museum is about all of you. Each item is renamed with the name of the person who created
        it. Please do not right-click examine the items when visiting. Every time an item is examined, there is a
        chance the signature will fade forever, and yours will replace it. I want to avoid that scenario as much as
        I can, so please keep actual examination to a minimum. For a more detailed explanation of this, please
        read The Preservation Process page in full. Thank you!
      </div>

      <div class="section">
        <h3 class="sm-title">Location - K15 on Independence SFI</h3><br>
        Rockcliff Museum is located down the road from Rockcliff Cathedral, on the E side. It is nestled in a corner of the base of Dragon Fang Mountain, and has a stunning view of the sea and Black Dog Island. It is built upon the lands that Tich settled when Independence opened. It is located at K 15, Independence, SFI. For specific directions, please go to the Museum Guide page. 
      </div>
        
      <div class="section"> 
        <h3 class="sm-title">Donations</h3><br>    
        <p>
        As I have said, this museum is for you. All of you. I want you all here, in name, as a signature on an item in this 
        museum. It doesn’t matter if you have played for a week, and you made a spindle, or if you are a GM and can bring a 
        fantastic adamantine sculpture. I want something of yours here, because you all matter, and you all made this 
        beautiful world, and you all deserve to be remembered. You may also send or deliver items intended to be here on loan. 
        If you do that, you will get written confirmation that your item is on loan, and it will be returned to you at the 
        specified date. 
        </p>
        <p>
        I also want your stories. You can’t directly post stories, because that would be pretty epic to manage, but you 
        can send them to me so I can put your stories there. Your stories can be about your item here, or just about you 
        and your favorite thing you did in the game. Don’t limit yourself, please. If there is a story you want to tell, 
        tell it. Send it to me, Nirav, on the wurm forum 
        <a href="https://forum.wurmonline.com/index.php?/profile/76620-nirav/">https://forum.wurmonline.com/index.php?/profile/76620-nirav/</a>. 
        You and your stories are everything this world is made of. Before donating, an item or an item with a story, I ask that 
        you please go to the page titled “Preservation Process” and look it over.
        </p>
      </div>

      <div class="section"> 
        <h3 class="sm-title">Contributors</h3><br> 
        I, Nirav, am the curator, and there are many to thank, from those who sent an item or more, to 
        those who helped imp items for signatures. But a few have done way more than that, and I would 
        like to thank them here:    

        <p><b>Folks Who Have Donated a Lot of Items:</b>  Pomeray, Vinius and the Kingdom of Macedon, Hughmongus 
        and Skyefox, and Crimsonearth have all donated over 100 items with signatures to the Museum. Nalimar 
        has donated over 100 deed tokens. The Museum also has received a significant Anonymous donation.</p>

        <p><b>This Website:</b>  It was created and is maintained by Camaril, who has been dedicated to this dream 
        of mine since I first told her about it. </p>

        <p><b>Folks Who Helped Build:</b>  Camaril, Stormblade, Zimgraham, Chaoshaze, Lilychaos, Spacy, Lyhrinae, 
        and Nirav. Camaril also has terraformed to create the massive terraces for the Hall of Alliances and 
        the future NFI Building. Uzetaab built the Inns down by the dock. <br>
The     Rockcliff Museum Annex on Xanadu was built and is maintained by Spacy and Seriphina</p>

        <p><b>Ambiance:</b> The lights were colored and placed by Soraya who created an astoundingly magical atmosphere 
        that makes the nighttime Museum Grounds a mystical, exalted world. Kasumi did the interior decoration, 
        and while I received a large amount of items after that, her foundation is why the interiors are as 
        beautiful as they are. Nalimar took the Gods Display on the 3rd floor of the main building to the next 
        level with her wonderful sense of design and attention to detail. </p>

        <p><b>The Impers:</b>  Hvergi has supported this Museum since day one by imping anything I have sent to him to 
        find sigs since 2019. Both Elentari and Aaedean imped countless items in 2022 and 2023. In 2023, Aaedean 
        was invaluable and imped over 1200 items for signatures just in that year. Since the Gala in 2023, Uzetaab 
        has been phenomenally supportive, and has imped over 1000 items for signatures and for maintenance. His 
        dedication to the Museum is humbling. <br>
        The following folks have helped imp hundreds of items to find signatures and to maintain the displays: 
        Rivenwind, Shenjiwurm, Bunnlose, Gumbo, Teeebomb, Reflekted, Seriphina, Spacy, Sweetsusie, Atheline, Nalimar, 
        Edborough, Merfin, Quelon, Kindrashae, and Killashandra (the first toon from NFI to come help with the Museum. 
        Without the folks willing to improve these items, the Museum would be less than half what it is. These people 
        have all really gone above and beyond for this Museum.</p>

        <p><b>Folks Who Have Supported in Other Ways:</b> Thank you to Bunnbunnnn for creating a discord channel and a 
        continuing hang-out place with Guestbook, as well as providing video and content. Thank you to Kasumi for 
        making me a beautiful curators outfit. Thank you to Reddragon for honoring this place at every Gala with his 
        bartending excellence - he does a phenomenal job - you really feel like you are in a tavern.</p>

      </div>

      <div id="footer" > 
        Wurm Online is a game produced by Code Club AB. Wurm Online and Code Club AB do not endorse and are not responsible or liable for any content, products, services or information available on www.rockcliffmuseum.com. This website is not connected to Wurm Online nor is it the official website. Please visit the Wurm Online Official Site at www.wurmonline.com
      </div>

      <br> &nbsp; <br> 
      
    </div>
    
    
  </div>
</template>


<script>
  export default {
    name: 'MuseumHome'
  }
</script>


<style scopped>  

.welcome {
  display: block;
  margin: auto;
  /* text-align: center; */
  margin-top: 10px;
  border: 1px solid orange;
  padding: 10px;
}

#footer {
  /* background-color: black;
  color: white;
  width: calc(100vw - 5px);*/
  border-top: 2px solid rgb(47, 60, 126);
  padding: 20px; 
  margin-top: 80px;
}
.discord {
  width: fit-content;
  margin: auto;  
  margin-top: 10px;
}
</style>