var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"maindiv"},[_c('div',{staticClass:"textsection"},[_c('h1',[_vm._v("Hall of Alliances")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"alliances"},[_c('h3',[_vm._v("First Floor Displays, PVE")]),_c('p',[_c('b',[_vm._v("POP The Magic Dragon")]),_vm._v(", Independence, Dec 2012 to Nov 2016"),_c('br'),_vm._v(" A village with around 20 players. "),_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$emit('chpage', 'stories', 'pop')}}},[_vm._v("See Story")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',[_c('b',[_vm._v("Silent Hill Alliance")]),_vm._v(", Independence, A Very Long Time ago to Current "),_c('br'),_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$emit('chpage', 'stories', 'silent')}}},[_vm._v("See Story")])]),_vm._m(7),_vm._m(8),_vm._m(9),_c('p',[_vm._v(" ")]),_c('h3',[_vm._v("Second Floor Displays, PVP")]),_c('p',[_vm._v("The Kingdoms represented here in the Hall are:")]),_vm._m(10),_vm._m(11)]),_vm._m(12),_c('p',[_vm._v(" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The Hall of Alliances was created to showcase various communities in Wurm. Alliances and other community groups are invited to do a one tile display with items that have signatures from their community mates, so everyone can be together in the Hall. This is open to current and past alliances."),_c('br'),_vm._v(" Like the main Museum building, there is a second floor for Kingdoms found on Chaos. 20 Kingdoms past and present are currently represented in the Hall with displays of items with signatures of players who were/are in those Kingdoms."),_c('br'),_vm._v(" Nirav and Camaril terraformed an area north of the Museum grounds to build this new building. It will be fully completed and filled with displays in time for the Gala Sept 3rd, 2023. There is still room for more displays. If your alliance or kingdom is interested, please contact Nirav on the wurm forum or in game. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"images"},[_c('img',{staticClass:"size",attrs:{"src":require("../assets/hall of alliances.jpg"),"alt":"Huge white marble building lit with pink lamps"}}),_vm._v(" "),_c('br'),_vm._v(" Hall of Alliances ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Shepherds of Gnome Alliance")]),_vm._v(", Independence")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Port Benden Alliance")]),_vm._v(", Celebration")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Elysian Academy Alliance")]),_vm._v(", Pristine, Dec 2012 to Current"),_c('br'),_vm._v(" We are a combination of Elysian Fields started by Sidereal Dec 13th 2012 and Academy Alliance started by Explora or LadyGodiva about the same time.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("NEXA Alliance")]),_vm._v(", Xanadu, 2015 to Current")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Echoes of Silence Alliance")]),_vm._v(", Pristine, Nov 2013 to Current")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("ALBIA Allliance")]),_vm._v(", Independence, 2014 to Current")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Identity Crisis")]),_vm._v(", Independence, 2022 to Current")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("The Blue Banners")]),_vm._v(", Zanadu, May 19, 2021 to Current")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Template MR")]),_c('br'),_c('b',[_vm._v("Template JK")]),_c('br'),_c('b',[_vm._v("Template HOTS")]),_c('br'),_c('b',[_vm._v("PMK MR")]),_c('br'),_c('b',[_vm._v("PMK JK")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Black Legion")]),_c('br'),_c('b',[_vm._v("Ebonaura")]),_c('br'),_c('b',[_vm._v("Macedon")]),_c('br'),_c('b',[_vm._v("Dreadnaught Dynasty")]),_c('br'),_c('b',[_vm._v("The Crusaders")]),_c('br'),_c('b',[_vm._v("Wurm University")]),_c('br'),_c('b',[_vm._v("Valhall Descendants")]),_c('br'),_c('b',[_vm._v("Pandemonium")]),_c('br'),_c('b',[_vm._v("Legion of Anubis")]),_c('br'),_c('b',[_vm._v("Apocalypse Order")]),_c('br'),_c('b',[_vm._v("The Spartan Empire")]),_c('br'),_c('b',[_vm._v("Heroic Libila Legion")]),_c('br'),_c('b',[_vm._v("Pirates")]),_c('br'),_c('b',[_vm._v("Knights of Valrei")]),_c('br'),_c('b',[_vm._v("Cheese")]),_c('br'),_c('b',[_vm._v("Kenn-Jellon")]),_c('br'),_c('b',[_vm._v("Capybara")]),_c('br'),_c('b',[_vm._v("Libila's Dominion")]),_c('br'),_c('b',[_vm._v("Hot Dog")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"images"},[_c('div',[_c('img',{staticClass:"size",attrs:{"src":require("../assets/front of hall of alliances.jpg"),"alt":"A large entrance under a huge arch"}}),_vm._v(" "),_c('br'),_vm._v(" Front of Hall of Alliances ")]),_c('div',[_c('img',{staticClass:"size",attrs:{"src":require("../assets/work in prog.jpeg"),"alt":"museum aerial view with two diggers working on expansion"}}),_vm._v(" "),_c('br'),_vm._v(" Aerial view of Camaril and Nirav terraforming the area for the new building"),_c('br')])])
}]

export { render, staticRenderFns }