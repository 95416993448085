import { render, staticRenderFns } from "./MuseumHome.vue?vue&type=template&id=0ddabb86&"
import script from "./MuseumHome.vue?vue&type=script&lang=js&"
export * from "./MuseumHome.vue?vue&type=script&lang=js&"
import style0 from "./MuseumHome.vue?vue&type=style&index=0&id=0ddabb86&prod&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports