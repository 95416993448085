<template>
    <div class="page">   
      <div class="story">
        <h3 class="sm-title">Nirav's haiku Contest</h3><br>
        <p>
          Nirav got an archaeology affinity during the holidays and got super excited and did a 12 Days of Giveaways 
          ending in a Haiku contest for scale on the forums. <br>
          Some of these were official entries and some were posted in chat just for fun. Here are all the Haikus:
        </p>
        <p>
          The winning Haiku: <br>
        </p>    
        <p>
          Wandering the trees <br>
          Finding ripe fruit and flowers <br>
          But where did I park? <br>
          - By Seriphina <br>
        </p>    
        <div class="extraspace"></div>
        <p>
          The others:
        </p>
        <p>
          Swooping from afar, <br>
          A random spawn seeks his prey. <br>
          Fight or flee, we must! <br>
          - By Sweetangel  <br>
        </p>
        <p> 
          With measured patience, <br>
          I will find deliverance, <br>
          imping my spindles. <br>
          - By Johnston3 <br>
        </p>
        <p>
          #1  <br>
          A brightly lit forge, <br>
          Rain echoes the sizzling coals, <br>
          The smith is now pure. <br>
          #2  <br>
          A dying forgelight, <br>
          Bang! The smith curses loudly, <br>
          He nurses his thumb and wonders why he took this job for 20 coppers... <br>
          - By Elentari <br>
        </p>
        <p>
          Ancient terror flies, <br>
          Armies March banners held high, <br>
          The beast watches us. <br>
          -------------- <br>
          My dragon inspired Haiku <br>
          -By Khellus <br>
        </p>
        <p>
          Wurm is Life, dig dirt <br>
          The Meadows, a peaceful home <br>
          Nature's symphony <br>
          - By DaletheGood <br>
        </p>
        <p>
          #1. <br>
          The arrow strikes true, <br>
          Like a silent assassin.... <br>
          And then it shatters! <br>
          #2. <br>
          Do not need Silver <br>
          Didn’t really need scale,  <br>
          Entered for giggles <br>
          - By Uisge Beatha <br>
        </p>
        <p>
          Riddle me this, <br>
          Riddle me that, <br>
          The wolfpack is howling and thats a fact <br>
          - By Dadler (NFI) <br>
        </p>
        <p>
          The mighty tree falls. <br>
          The carving knife soothes the soul. <br>
          The sea calls my name. <br>
          - By Marduk <br>
        </p>
        <p>
          Bricks I'll make with pride, <br>
          Till the day that I die, <br>
          My craft never tires. <br>
          - By Echowaldo <br>
        </p>
        <p>
          "Thwack" goes the pickaxe. <br>
          The lantern suddenly dark-- <br>
          A miner problem. <br>
          - By LadyCygnet <br>
        </p>
        <p>
          Independence Home <br>
          Waves lick at the ancient shores <br>
          Valrei warms my soul <br>
          - By Jackdryden <br>
        </p>
        <p>
          this city is dead <br>
          but by grinding on its grave <br>
          it will never die <br>
          - By Docterchese <br>
        </p>
        <p>
          Solrise at northwest <br>
          Heart contracts day after day <br>
          As warm as wurming <br>
          - By Coach <br>
        </p>
        <p>
          There once was a man from Nantucket......um.....ah.......nevermind. <br>
          - By Gwiz <br>
        </p>
        <p>
          Got lost in the woods <br>
          Trolls are not meant to be friends <br>
          R.I.P. The End. <br>
          - By Aeris <br>
        </p>
        <p>
          What a day to search! <br>
          Fragment hunts are so much fun <br>
          What will I find here? <br>
          - By Jingerjas <br>
        </p>
        <p>
          Behold the champions! <br>
          Offer them your soul and flesh <br>
          Blessed death awarded. <br>
          - By Illusion <br>
        </p>
        <p>
          My steed, Pickfancy, <br>
          is fast and can pull a cart. <br>
          He is old five speed. <br>
          - By Agentquarty <br>
        </p>
        <p>
          All roads lead to Wurm. <br>
          Don't forage in the pig pen if you want high-ql results or clean hands! <br>
          Can you check your perms? Oh wait, you're standing too close. XD <br>
          MOIs are the glitter in the rainbow. <br>
          All roads lead to Wurm. <br>
          - By MordosKull <br>
        </p>
        <p>
          This contest looks fun, <br>
          The prize, the rhymes, but alas <br>
          I am in the North <br>
          - By Luobo (NFI) <br>
        </p>
        <p>
          Casts Rebirth on a troll <br>
          Says to it "sick 'em" <br>
          Sits back eating popcorn <br>
          - By Tomatoes <br>
        </p>
        <p>
          You need a hammer <br>
          To improve your new hammer <br>
          I present: wogic <br>
          - By Schiann  <br>
      </p>
      <p>
        #1   <br> 
        Terraforming so slow   <br>
        Will this deed ever be level   <br>
        Surface mining sucks  <br>
        #2  <br>
        My first haiku sucks  <br>
        with practice I will improve    <br> 
        seasonal reference   <br>        
        - By Shandezram (NFI)  <br>
      </p>
      <p>
        To dig down the hill  <br>
        endurance sandwich, wine flask,  <br>
        stave death? Not today  <br>
        - By Lyhrinae  <br>
      </p>
      <p>
        In the still of morn  <br>
        Peasant's saw holds steady tune  <br>
        Planks take shape beneath  <br>
        - By Breadmenace (NFI)  <br>
      </p>



      </div>
      
      <br> &nbsp; <br> 
    </div>
</template>


<script>
  export default {
    name: 'HaikuContest'
  }
</script>


<style scopped>
  .story {
    margin-top: 30px;
  }
  .extraspace {
    height: 20px;
  }
</style>