<template>
    <div class="page"> 

      <span id="usetaab"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">For Uze from Bara - The Historical Toolbelt, by Uzetaab</h3><br>
        I played Wurm a long time ago. Tich was the woodworker, and I was the smith [at their village on Release]. When toolbelts were invented, I only knew one leatherworker. His name was Barakus. He made a toolbelt for me. I was not online when he came to deed to bring it to me, so he gave it to Tich to give to me. It is one of the first toolbelts ever made in the game. And I still have it. It still says For Uze from Bara. It belongs in a museum. Barakus was a New Zealander, and playing this game in his 80’s. Barakus was his player toon. He was also head GM as Oracle. <br>
      (This incredible item is in a magic chest on the 3rd floor of the main building)
      </div>

      <span id="nineol"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Nineol’s Hammer and Ehizellbob’s Tapestry – Nineol</h3><br>
        Nineol contacted Nirav to see if two items could be donated. The first, Ehizellbob’s Tapestry, is one of only four tapestries with her signature in the game. Ehizellbob named the 100 skill level title for Scythe, but also won a contest allowing her art to be in the game as Tapestry of Ehizellbob and chose this as the better donation.  Nineol named the title for 100 skill level in Hammer, Weapon-Smithing, and Smithing. His rare iron hammer has been donated to the museum.  Their display is together because they met in Wurm and fell in love.  
      </div>
      
      <span id="nirav"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Waarokku’s Mallet by Nirav</h3><br>
        Waarokku was one of the first people who spoke to me and helped me.  He chatted with me that first week for hours. I knew him as a patient, kind, brilliant person long before I ever saw his shenanigans in GL, which I found to be delightful. He let me ‘friend’ him, and we chatted off and on for the next 5 years. When I built the first small version of the museum on my deed, I asked Waarokku for something for it, and reminded him from time to time.  In February of 2021, I sent him a Valentines Day present and we talked, and I pretty much demanded that he send me something for the museum. “[18:05:34] &lt;Waarokku&gt; yes, going to make a mallet. fencing is my signature. going to imp it high. have it made, just have to finish it.” He sent it less than a month before he left us. That mallet might be the thing in the entire museum that means the most to me. The mallet is on a table in the Smithing area of the First Floor of the Museum.
      </div>

      <span id="hrig"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">If Found PM Beastwolf by Hrig</h3><br>
        Chaos is full of stories that are centered on people doing ludicrous, daring, and impressive things. I was impressed by what Beastwolf did in 2020. Beastwolf ran all over Chaos and picked locks on boats, wagons, and carts, and then put these signs next to each ‘stolen’ item. The item was left where it was, but relocked and owned by him, and renamed with “If Found PM Beastwolf” or with a sign saying that next to it. This is no small task. He traveled extremely long distances at great risk to himself in order to do this, and lockpicked countless boats, carts, and wagons. Hrig found it extremely amusing, and stole a sign and sent it to Nirav for the Museum.  Yes, Hrig IS Nirav, but since Nirav doesn’t go to Chaos, she had to get one for him. One or two of her items was ‘stolen’ in this manner. 
      </div>
      
      <span id="necros"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Tenko’s Story About Necroe’s Wagon</h3><br>
        <p>
          I am a Nirav alt who goes over to Chaos from time to time to loot abandoned deeds to find things for the museum. When the JK PMK fell, I waited a bit because my body strength was 24 or something godawful like that, and I could be killed if someone who did PvP regularly merely looked at me. After a few weeks, I slunk over there and started digging around, bashing into mine doors that were sealed still, or likely re-sealed after the big loot job that was done the first couple weeks by Everyone. In one area, I got into a mine and found a JK PMK wagon. It had a fantastic lock, was made by Necroedarkslayer, and I had a lockpick skill of 11. The rest of the cave had tools, and other items, but nothing this valuable. I wanted that wagon more than anything I had ever seen in the game up until that moment, and had a chance in hell of getting it. 
        </p>
        <p>
          While I hatched a plan, I was Terrified the entire time that Necroe and crew were going to log in any second. I asked a friend to make me picks and mail them, since there was a mailbox in the cave with me. My picks were not even allowing me a chance to pick the lock. I was starving, and lit an oven and searched an FSB and found some ingredients and threw them in some skillets I found in the oven. This was pretty much to do something so I wouldn’t vibrate from fear. While the meals were cooking, the lockpicks got sent over. My friend already had picks, and being a very good friend, he mailed me 100 of them in a satchel, half of them over 90 ql. I went to get the meals, and my skill in cooking was so low that my skill boost from the meals was about 3 minutes. Curious, I checked to see what skill was boosted. Lockpicking. I looked again. Lockpicking. I couldn’t believe my luck. I pulled out a 99 ql pick and settled in for what I expected to be a very long evening because I had a 1.30% chance of picking that lock with the meal, my skill, and a 99ql pick. On the first try, the lock popped open. I about fell out of my chair. 
        </p>
        <p>
          I made another mine door, imped it to 40, slammed it on the mine, and ran all over the area gathering grey steppe horses so I could get the wagon out of the mine. I expected to die any second. But no one ever showed in local. I sailed away with the wagon in my knarr, whooping and hollering like a banshee, all the way to Inde to hide it behind my house.  I was That afraid of Necroe. I was sure if he happened to ever ride by and see the wagon, I would die instantly, whether I was on Chaos or not. When the big museum opened, I finally decided it was time to show the wagon to everyone and boast about the fact that I stole a wagon owned by Necroedarkslayer. He may yet kill me.  But it was worth it.
        </p>
      </div>

      <span id="tenko"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">The Tower – By Tenko</h3><br>
        <p>
          In Feb of 2022, an amazing thing happened on Xanadu. There was an abandoned deed along the North coast with a tower named Gonzopl. It was a one tile 7 or 8 story brick thing that drew six people and transformed us all. On the ground floor, which had arches on all four sides, was a table with great riches, a banner, and a few other delights. Me, Kasumi, Chabney, Bichix, Payu, Ctpax, and Balatyn were all staring at these riches and hoping the tower would fall soon. I know Kasumi, and Payu and I had crossed paths years ago, but the others were strangers to me. I was there to get something for the museum, and was totally happy to divide everything with the other 6 people. Kasumi was totally on board for that, but how to convince the others that this was better than just one of us rushing in and grabbing everything? 
        </p>
        <p>
          The Tower took days to fall.  In that time, something magical happened. Kasumi and I were already on the same page, but she was quite skeptical that my plan to get people to share was going to work. I don’t blame her. Chabney and I began a friendship that we will never forget. He is a delightful soul, and the real treasure under Gondopl was finding his friendship and spending time listening to him talk. We shared some of the moments in Wurm most precious to me, watching sunsets, laughing, speculating about life and the universe while sitting there waiting for a wall to fall. Payu and I didn’t get along well before that week, but after days at the tower, he and I found new respect for each other and buried the hatchet. Bichix and Ctpax were not able to be there for long moments, logging in merely to check the damage ticks and logging back out.  They were two of the first to agree to sharing. I wasn’t sure if they would keep the promise to share if they found themselves the only ones awake when it fell, but they sure seemed to agree. Balatyn was far more reluctant. He couldn’t seem to fathom that any of us would actually keep a promise to share items. But after days there, even he relented and agreed that sharing would be better.  Greed was slain that day under the Tower. I asked everyone what they wanted, in local, and we discussed things and decided on how to divvy everything up.  
        </p>
        <p>
          When it finally fell, I was standing there.  So was Balatyn, but he was afk and no one else was around. It was nighttime, and so peaceful. I yelled at Balatyn to get his axeman that he wanted. I was kind of rooted to the spot and couldn’t move, like a deer in the headlights. But Balatyn remained quiet, so I moved and picked up the table, in disbelief.  It felt so strange that this tower that would not fall for 5 days was suddenly gone, and gone it was.  When it went, it went entirely. I picked everything up and waited for Balatyn.  He came back a few minutes later.  I handed him his item and began mailing the others, waiting for Chabney to log on so I could give him his. Ctpax said at one point that he wanted to share a Russian proverb: “You cannot share the skin of an unkilled bear”.  Maybe, when everyone is motivated by sharing rather than greed, you can once in a while do just that. Bichix never got her banner.  I could not reach her, but I am still trying. It is currently at the Museum. The items I got over to Nirav are here, too. But that was only my share. The rest of the loot went home with 6 friends. And I sailed away far richer because of those friends.
        </p>
      </div>

      <span id="bunnbunnnn"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">The Easter Egg by Bunnbunnnn</h3><br>
        Pretty much the story behind that egg was that I got it during the
        first held Impalong at the Deli Festival deed. I was staying with the
        group there, and helped here and there to make the deed. Nothing too
        much worth of note. I had been party hopping xD.  I started my toon
        during the Lunalong on Xanadu, and the Deli group adopted me, and I
        came to the alliance Festival deed to help support the build as a
        minor player. I think I mostly helped with carving out the cave system
        here and there. Then they hosted a grand opening it was on easter. And
        tons of little rabbits were dropping eggs, and I collected some and
        kept them safe in my LMC. I took them all the way to Celebration map
        where I currently reside.
      </div>

      <span id="sidereal"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Wine with Mae and Stoney by Sidereal</h3><br>
        I was there the night Pristine was created - back in the year 1028,
        running naked and afraid trying to find a safe place to deed along
        with a hundred other wurmians. Several hours and deaths later, I found
        myself alone on the south coast wondering how many days or weeks it
        would take another soul to make it this far as I logged out.
          Well the next morning, the entire coast had been deeded and I met my
        new neighbors. Larincette to the east, who immediately built a high
        stone wall along the entire length where our perimeters met. Ah well.
        Mae and Stoney to the west on the high ground overlooking the bay in a
        deed called Braewood. Further yonder, Notstupidbobb and Exene in the
        lovely Glengarry.
          None of us had much of an idea how to play, but we learned together
        and tried different things and as the weeks turned to months, we
        became friends. We struggled with our low skills, had no idea how to
        grind, but somehow I managed to collect and plant some grape vines, a
        little maple sap, and turn them into wine of embarrassingly low
        quality.
          It didn't matter - everyone was excited we had come so far as to
        make actual wine! We all had a taste of my trash wine around a
        campfire up at Braewood in the winter of 1030 to celebrate our
        struggles and the small, ramshackle deeds we'd managed to build. It
        remains one of my fondest memories of wurm to this day.
          ps - Mae and Stoney left some months later and I haven't seen them
        since. The guard tower by the road still bears her name. I still keep
        them in my friends list (last see 3012 days ago) just in case they
        ever come back.
      </div>

      <span id="pop"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">POP the Magic Dragon - by Camaril</h3><br>
        <p>
          POP the Magic Dragon was a deed on the East Edge of Dragon Fang Mountain. Swordfish and Hdptrequalsnull settled there in December 2012 and Kulaan and I joined them shortly after. The area was deeded in March 2013 and we built the Oratory of Magranon as our first major building project. It wasn't huge, but for us it was a work of art and gave Kulaan a place to serve our god as priest. 
        </p>
        <p>
          We soon welcomed a bunch of new villagers. We were college students and all of the villagers were friends, classmates and teammates that we talked into playing with us. While a few played only briefly, many of them stayed and played seriously and POP the Magic Dragon flourished. We all got along really well, and most of us were makers, so we loved doing projects together. 
        </p>
        <p>
          We expanded the deed to be much larger. A new expanded workshop was built, as well as new housing for all of the villagers. Run, our smith, lived in a little cave with his forges, though that was long before you could build buildings underground. As the resident digger I took on the massive project of leveling out the whole area on top of the ridge that the deed sat upon. A tree farm was added and a massive storage structure. 
        </p>
        <p>
          We enjoyed doing large building projects together and needed another project so we decided to build a pair of colossus. We expected them to take all summer but with a big group and a lot of enthusiasm, they were done much faster than expected. After that, I started very slowly building a knarr, while the guys came up with a boat tunnel project. They wanted to build a canal under Dragon Fang mountain, running North to South, connecting Crystal Lake to the Northern sea. I think they ultimately succeeded, though they hadn't understood how many deeds were in between, or how putting tunnels through at such a low level might mess up a few things in the mines people had above. I think there were a few upset people, and the canal was a tricky, winding thing that was prone to cave ins because the walls weren't reinforced. 
        </p>
        <p>
          From there, Hdptrequalsnull, Swordfish, and Lost Oracle, and myself decided to try out PVP on chaos, and Jenn-Kellon welcomed us into their kingdom. We lived at Crossroads. It didn't have dirt fortifications yet and I set about digging as much dirt as I could. We lived in the walls – the single tile wide, long buildings that wrapped around the deed. Eventually the walls rose and we could build houses, but the other POP members were already fading away. Hdptrequalsnull, Swordfish, and Lost Oracle had graduated and life was changing – leaving campus, starting careers, and marriage. I stayed but I was playing less, and then JK announced it was disbanding and I moved back to Independence along with Hdptrequalsnull and Swordfish. 
        </p>
        <p>
          At some point, a good friend of mine decided to try Wurm and against all good judgement I invited them to start along with a new alt of mine, Lailani, on Chaos. That friend was Nirav and a few weeks later, when they needed rescuing, a few of the POP group joined me, to sail over in my knarr to pick them up and explore a bit of the area. 
        </p>
        <p>
          When POP disbanded, a few of the folks who were still interested in maybe playing from time to time, came to live at my small personal deed, Outlaw Bay. Later when I moved to Raider Nation some of them moved over there with me. However, they didn't start playing again, and I can only hope that eventually I can get them to come see how many cool new things there are to do.<br>
          <span class="link"><a href="https://www.wurmpedia.com/index.php/Settlement:POP_the_Magic_Dragon" target="blank">see wiki page</a></span>
        </p>
      </div>

      <span id="silent"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Silent Hill Alliance - by Aeris</h3><br>
        <p>
          Silent Hill (PvE) has existed in some form for almost as long as Wurm Online. Eliasthecrimson and Froggeryz have both been 
          playing Wurm Online since its Gold 1 phase, and they've taken in many newbies over the years. A lot of people have helped 
          shape the village over the years, such as: Tombat, Dorian, Lancelot, Aeris, Kingjared, Foxton, Kylebooze, Kswords, Herrfritz, and 
          Handsomestranger - Just to mention a few!
        </p>
      </div>
      
      <span id="righout"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Three Stories By Righout, HLL 2021-2022</h3><br>
        First one: <br>
        Autir BlowUp Doll - Autir was a new member of the kingdom, still fresh, Chaos born and forever to stay there. With him being new came the problem of not having any combat skills. That is what brings us the subject of this story - a practice doll. 
        It was requested from me by Morrowen who is also a kingdom member for Autir to use it and from him came the name. But while imping a surprise event happened - it became rare. Not knowing whether to cry or to laugh, I sent to them the information that it become rare and if Autir wanted it? Autir respond that he only needed a normal one as if my memory stand corrected, it might turn to dust while using it, so I created a new one and imped it to 80ql. And put the rare one on display on the second floor of my house with the ability to be seen from the outside. 
        And that was a mistake as it was subsequently stolen in a raid the next week. I don’t remember who the raiders were, but I remember they took that doll and my favorite rare frying pan. Anyway I have make a replica of the doll but unfortunately I couldn't rare it even though I used all my rare items (shafts and large nails) well I will still try rare a practice doll. Until then, I would like to donate a normal practice doll with my signature and the name Autir BlowUp Doll. 
        Autir found out about this and replied, [13:39:40] 	&lt;Autir&gt; Put a sign on it "I lived through a PMK from start to end, and all I got is this blow doll"
        <br> &nbsp; <br>
         
        Second story: <br>
        Not a week after I joined the HLL kingdom, Enemies trespass to our land for a raid. 
        The aggressors were Inquisition using a trebuchet. They rained upon us fire of silver ores mined from our own land. A call for arms was in need, and all able members needed to go. I was positioned on the wall repairing all the damage dealt with a help of another member Rev. But that wasn't helping much as we were bombarded and the walls had gone half health by now, and an enemy was on the other side of the wall breaking the chain that was not letting him in. 
        I made mistake then. I should have kept repairing but I stopped and started targeting him, even though he was on the other side. What can I say, I was a PvP newbie. The chain crumbled down and I was targeted. In no less them a minute I was dead but maybe it was my death, or how fast Rev ran, because he was able to go out without lasting damage as he went through locked gates while the enemy needed to picklock through them. 
        But the gear that was in my corpse was left outside the deed. I don’t know why, but probably because it was studded and they didn't like it, so it will be donated too. With also a huge axe that I used later to fight naked outside the deed against the enemy and we killed one of them even though they were stronger than us. We ganged up on him.  There is no shame in war. 
        <br> &nbsp; <br> 

        Third story: <br>
        This was the last days of the HLL kingdom. And this was the first raid on us where the deed was drained by Sparta. I was late to login and they weren't enough people to defend, so I had gone up the wall to check the situation on horse. But I see no wall at all in the east side. Suddenly I glitched and was thrown off my horse down the dirt walls. Then I was near the enemy with only half my health. 
        I didn't know what to do or where to go. if I didn’t act fast I was dead for sure in minutes as they are more than 8 people out there with what I think was at least 3 champions. 
        Quietly I went down the anti-raid cave on the wall so I can maybe hide and recover… maybe... 
        Ten seconds in there and I hear the footsteps of someone coming near. I need to run - I am not in any shape to fight. I went out of the cave to see a Vynora champion 7 tiles away. I don’t know how but I got suddenly an idea maybe it can help me survive. I climb the mound up as I was near the end of the dirt wall and the boat mine was below me. I only need to go down without dying from the fall and I should be safe as soon as I swim in to it. And that was indeed the correct choice. As soon as the champion was on me and was attacking, I fell down to 10hp near the boat mine then swam in to it so I saved my life. [19:40:03] Lundu tries to cut you. This was how close I was to him lol.
        All three items are on the Second Floor of the Museum near the front.
      </div>

      <span id="haiku"> &nbsp;</span>
      <div class="story">
        <h3 class="sm-title">Nirav's Haiku Contest</h3><br>
          Nirav got an archaeology affinity during the holidays and got super excited and did a 12 Days of Giveaways 
          ending in a Haiku contest for scale on the forums. <br>
          Some of these were official entries and some were posted in chat just for fun.
      </div>
  </div>  
</template>


<script>
  export default {
    name: 'StoryPage1',
    props: ['section'],
    mounted() {
      if (this.section != undefined)
        this.$nextTick(()=> window.document.getElementById(this.section).scrollIntoView());
    },
    methods: {
      send() {
        window.location.href = 'https://www.wurmpedia.com/index.php/Settlement:POP_the_Magic_Dragon';
      }
    }
  }
</script>


<style scopped>
  .story {
    margin-top: 30px;
  }
</style>