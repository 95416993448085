<template>
    <div class="maindiv">
      <div class="textsection centerthis">
        <div class="new-gala">   

          <h1>Rockcliff Museum Gala 2024</h1>

          <img class="galaimage" src="../../assets/RCM3.jpg" alt="View across the museum grounds from the roof top of the hall of aliances">
          
          <p class="first-paragraph">
            Rockcliff Museum celebrates its Two Year Anniversary Sept 7th from 10am to 10pm CST, and you are All invited! 
            This is the first year NFI will be able to attend. You can take a portal to The Howl and then run down the FM road 
            to the Museum. More detailed directions are in the Museum Guide page.</p>
  
          <p class="red">
            <u>There is One Rule that is Extremely Important for visiting the Museum. Please honor this rule:</u><br>
            The point of Rockcliff Museum is to showcase not the items, but the names of the people who made this
            world. Each item is renamed with the name of the person who created it. You do not have to examine the
            item to see the name - the items all have visible names on them. Please do NOT right-click examine the
            items. Every time an item is examined, there is a chance the signature will fade forever, and yours will
            replace it. We want to avoid that as much as we can, so please keep actual examination to a minimum.
          </p>


          <p class="green"> 
            The Gala will run from 10am to 10pm CST on Independence, at K 15/16<br>

            For information about how to get there, where to park, and more, please go to the Museum Guide page.  <br>

            This year, everyone can attend, including NFI. Portal to the Howl, and call for a summons to the Museum. 
            We have summoners this year. When you are ready to go, you can run back to the Howl fairly easily - it is 
            not real far. <br>

            Reddragon will be running his fantastic amazing Boozen Haus again this year!!!  <br>
            
            Camaril is making the Gala Statuettes again this year, and everyone who attends gets to take one home!  <br>
          </p>
          
          <p class="light-blue">
            Accommodations - Read to Reserve Rooms:  <br>

            Uzetaab has made a Far better Inn for everyone to stay in. The new Fairview Harbor Inn down at the docks 
            is a two-building Inn with individual entry rooms with a pasture for horses or wagons or carts right at 
            your front door. He did a fantastic job, and the Inn is much more cozy and accessible. <br>
            Accommodation is limited and visitors wishing to stay the night may need to reserve their room ahead of 
            time. PM Nirav to reserve a room and please specify if you need space for a wagon/cart or 1 or no mounts. 
            There is also space available for free camping if you bring your own tent.
          </p>
          
          <p class="green"> 
            Hughmongus will be running his awesome Albia Taxi Service to run folks from the docks to the Museum. 
          </p>
          
          <p class="purple">
            New for 2024:  <br>

            The Room of Anvils in the Observation Tower <br>

            Expanded PVP floor area with far more PMK items <br>

            1,827 Toons represented <br>

            Updated Memorial to those we have lost <br>

            The start of a new terrace that will hold the NFI building to be finished in early 2025 <br>
          </p>
          
          <p class="green">
            Put on your best garb or armor and join us in the festivities!  <br>

            And if your name is not here, it needs to be here. Because you also contributed to our world. 
            Contact Nirav if you would like to send an item with your signature on it. 
          </p>
        </div>


        <hr>
        <hr>
        <hr>
        &nbsp;<br>

        <h3>The Grand Opening took place Sat Sept 3rd, 2022</h3>
        <p>
          It was a glorious event with folks from all over the SFI and staff. <br>
          This was the forum post at <a href="https://forum.wurmonline.com/index.php?/topic/192098-rockcliff-museum-qa-details-about-the-grand-opening/">https://forum.wurmonline.com/index.php?/topic/192098-rockcliff-museum-qa-details-about-the-grand-opening/</a><br>
          Here are some images from that day: 
        </p>
        <div class="images">
          <img class="standard-size" src="../../assets/opening/od4 by Jacqulina.jpg" alt="Sea Monster">
          <img class="standard-size" src="../../assets/opening/od5 by Bunnbunnnn.jpeg" alt="view from the observation tower">
          <img class="standard-size" src="../../assets/opening/od3 by Jacqulina.jpg" alt="someone standing in a crate">
          <img class="standard-size" src="../../assets/opening/od5 by Jacqulina.jpg" alt="Reddragon's tavern">
          <img class="standard-size" src="../../assets/opening/od1by Lyhrinae.jpg" alt="memorial coffin with roses">
          <img class="standard-size" src="../../assets/opening/od6 by Kasumi.jpg" alt="View of the museum at night">
          <img class="standard-size" src="../../assets/opening/od2.png" alt="Nirav's speech">
        </div>
        <p>Thank you to Bunnbunnnn for creating a discord channel and a continuing hang-out place with Guestbook, 
          as well as providing video and content. Kasumi for making me a beautiful curators outfit. Camaril, 
          Zimgraham, Soluna, Bunnbunnn and Jacqulina all helped a ton. Reddragon honored this place with his 
          bartending excellence - he does a phenomenal job - you really feel like you are in a tavern.
        </p>
        <h3>See you at the Gala in Sept 2024! </h3>
        <br> &nbsp; <br> 
      </div>
    </div>  
  </template>
  
  
  <script>
    export default {
      name: 'Gala2023'
    }
  </script>
  
  
  <style scoped>
  h1 {
    width: fit-content; 
    margin: 20px auto; 
    color: rgb(194, 2, 194);
  }
  .centerthis {
    width: fit-content; 
    margin: auto;
    width: 90%;
    margin: auto;
  }
  .new-gala {
    width: fit-content; 
    margin: 0 auto 60px auto; 
    text-align: center;
  }  
  .galaimage {
    width: 94%;
    max-width: 800px;
    border: 2px solid black;
    margin: 10px;
  }
  .first-paragraph {
    color: blue; 
    font-size: 1.2em;
  }
  .red {
    color: red;
  }
  .purple {
    color: rgb(194, 2, 194);
    font-size: 1.2em;
  }
  .green {
    color: rgb(30, 194, 120);
    font-size: 1.2em;
  }
  .light-blue {
    color: rgb(30, 194, 255);
    font-size: 1.2em;
  }

  .images {
    width: 100%;
    align-content: center;
  }
  </style>