<template>
  <div class="maindiv">
    <div class="textsection">
      <h1>The Signatures</h1>
      <p>
        Here is a list of every signature in the Museum. This page is updated whenever 50 or more new signatures 
        need to be added to the list. If you have donated an item and are looking for your name, please know that 
        it might take a while. If you are concerned that it has been too long, or you think your name is misspelled, 
        Nirav wants to know. Great care is taken to ensure that all signatures are present and accurate. Also, if you 
        see a signature in the museum that is not on this list, that is something Nirav wants to know as well. For any 
        of these reasons, please contact Nirav on the 
        <a href="https://forum.wurmonline.com/index.php?/profile/76620-nirav/">Wurm Forum</a>
      </p>
      
      <div id="namelist">
        <div v-for="(group, letter) in allNames" :key="group[0]">
          <h3>{{ letterList[letter] }}</h3>
          <span v-for="(name, id) in group" :key="name">
          <span v-if="id != 0">, </span>{{ name }}</span> 
        </div>
      </div>

      <br> &nbsp; <br> 
    </div> 
  </div>  
</template>


<script>
  export default {
    name: 'MuseumNames',
    data() {
      return {
        letterList: ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
      }
    },
    computed: {
      allNames () {
        return this.$store.getters.getAllNames
      }
    }
  }
</script>


<style scoped>
  #names {
    border: 2px solid green;
  }
  #namelist {
    margin-top: 30px;
  }


</style>