import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    namesList: [
      "Aaedean, Aalerion, Aamanus, Aarontx, Absy, Acacia, Acaos, Accwin, Acetrainerd, Acidinmyfridge, Adama, Adamz, Adelhard, Adog, Adoll, Advent, Aeresa, Aeris, Aesir, Aesys, Aetherian, Aetherion, Aetherwalker, Aexies, Agememnon, Agentqwarty, Ahelismortagne, Ahsesino, Aida, Aidya, Aikainnet, Aja, Ajaxx, Akaryd, Akolafox, Akronyhm, Alannah, Alaskanbob, Alat, Aldatura, Alden, Aleanda, Alectrys, Aleksandra, Algoron, Alinicon, Alkey, Alkhadias, Allan, Allie, Alpengeist, Alphafat, Alterbridge, Altsaxen, Alyeska, Alythia, Amarrian, Amath, Amberrain, Amberrich, Ammar, Amonthalas, Amylhia, Amyxia, Anabel, Anacrusis, Anakin, Analice, Anayyatta, Ancei, Andderson, Andianyu, Andistyr, Andraste, Andrewwhitim, Andriod, Anergo, Angelghost, Angelklaine, Anhur, Aniceset, Animus, Anirionin, Ankhesenamen, Anki, Annalinda, Annette, Annykin, Anothernoob, Anouca, Antonslavick, Anvil, Aoirdis, Aquaman, Arakiel, Aramisii, Arasyn, Arathorn, Arcadiosd, Archaed, Archic, Arcticcyanide, Argell, Arianne, Arimus, Aristia, Arkobi, Armorwolf, Armyskin, Aroma, Arrelosia, Arvid, Arx, Ascorbic, Asdf, Asguardia, Ashawolfgrim, Ashta, Asidecay, Asirtan, Askavargna, Astrofugamen, Aswang, Asylumghost, Atheline, Aubbypoison, Audio, Audrel, Aum, Aurelliusblack, Aurem, Autir, Avenger, Averthus, Awardis, Axatzen, Azaron, Azazelchiara, Azetech, Azjherben, Azmorlum, Azraiel",
      "Baalinor, Baboso, Baby, Babyseal, Bachus, Backsnap, Baconator, Baddbob, Bael, Baha, Baileythefe, Bakeruss, Bakhita, Bakteria, Balack, Balatyn, Baloo, Bamoo, Bangzuvelis, Barakus, Bard, Barnoldswick, Barnsen, Barricade, Battlepaw, Bcw, Beafush, Beanbag, Beastwolf, Beckett, Beelzebub, Beerdrinkinredneck, Beilla, Beka, Belfesar, Beliphur, Bellaamore, Benclawedodamme, Benjames, Berms, Berris, Beth, Betrayrofhope, Bialebambo, Bigbouy, Bigox, Bigtrout, Bilbopk, Billingsley, Bink, Birbmcpriestyyy, Birdbeback, Birdwatcher, Bismoll, Bitterreinder, Blackarrowpig, Blackbeardxiii, Blackburn, Blackfox, Blackmere, Blackmonk, Blaike, Blasphemer, Blgpapa, Blindpanic, Blinksyrevi, Blinksyrevived, Blob, Blodeuwedd, Bloodmaster, Bloodscythe, Bloodyfo, Bloodytank, Bluemood, Bluslate, Bobfrost, Bogas, Bolov, Bombora, Bonefly, Boobaby, Boriso, Bossie, Brainer, Bramsky, Brandonsf, Brattygirlsback, Breanna, Briance, Briannick, Briarstone, Briarwood, Brickwall, Brighde, Brix, Broken, Brokenbow, Budzilla, Bumbum, Bummer, Bunnbunnn, Bunnlose, Bunnyman, Burningrubb, Burwash, Butoxy, Butterbean, Byran",
      "Caimbridge, Caindarren, Cairmor, Cakos, Calder, Calebxephrime, Caledoras, Calisthra, Camaril, Cambriaa, Cannibalistic, Capnsavaho, Captainmonro, Captainturb, Captnmorgan, Caradal, Carnick, Cartercow, Catullus, Cecci, Cenotaph, Cerberus, Chabney, Chahles, Champagnedragon, Chaoshaze, Chaoskiller, Chaseone, Cheekybuns, Cheekyman, Chefpeewee, Cherlindrea, Cherry, Cheshirekat, Chicol, Chidoku, Chieuse, Chillbear, Chiry, Chlodovech, Chocobro, Chrio, Chrisfrankie, Christa, Chriton, Chronas, Chronicbuds, Chryspano, Chubchub, Chulirac, Chumpzor, Cichymd, Cinderr, Ciray, Cita, Ckczk, Clasin, Clerkjamesi, Clifford, Cohenworrior, Commanderhonk, Comodo, Comradebigt, Contego, Coretin, Corgon, Cormax, Corrax, Costume, Cowbot, Crashtestvingtsept, Crass, Crawford, Crazybill, Crazydad, Creeperthemob, Crimsontide, Crisalgon, Croc, Croma, Cubeman, Cybaru, Cybersnake, Cyklista, Cyz, Czolg",
      "Daadaaa, Daash, Dacook, Dadamage, Dadd, Daes, Dagobert, Daiken, Dairuka, Daldrin, Dalethegood, Damascus, Dameblanche, Damine, Dani, Danielharris, Danius, Danjii, Dannyiron, Dano, Dantte, Daolin, Dapperdan, Dareak, Dargonslayer, Dariuspl, Darkblas, Darkhaslador, Darkkonn, Darkmaiden, Darkmalice, Darkprince, Darkravager, Darkstriker, Darsonir, Dart, Dartaranius, Darwin, Davide, Davy, Ddddestroyer, Ddrosler, Deanys, Deathangel, Deathreus, Debernius, Decoy, Dedri, Deia, Deila, Delakar, Della, Delll, Deltadoggg, Delvito, Demonduo, Demonianightshade, Demonina, Dergen, Deth, Dewati, Dez, Diceusinfinitus, Difrost, Dimmi, Dinith, Dipper, Dirk, Djevick, Djuriusms, Docbrawn, Docterchese, Doctorteeth, Dogukakugi, Domhall, Donyell, Dor, Dorian, Doric, Dottie, Doubleaiden, Doxi, Dracaa, Draconobilis, Dragonqueen, Dragonwyck, Dragoon, Drakenedblackmoon, Draxxle, Drent, Drgonheart, Dridmar, Drikon, Drmonkey, Drmonone, Drn, Droldle, Dropdeadfred, Drsatan, Drvst, Drygpelle, Duce, Duckandcover, Dudeguy, Dukedubits, Duncanidaho, Dunfly, Dups, Dustinahlst, Dwain, Dxvinpl, Dylancrendraven, Dylyan",
      "Eagle, Earlymornig, Easton, Eber, Ebriel, Echowaldo, Edborough, Eddiemunster, Edmoney, Edricka, Edwardteague, Edz, Edzwarr, Efevee, Efia, Eggsplat, Egnomar, Ehizellbob, Eiffel, Einherjer, Eir, Ejay, Ekib, Ekino, Eleangel, Elenonia, Elentari, Eleraan, Eliasthecrimson, Elizarya, Elkadar, Elregis, Elrien, Elsa, Elska, Elwood, Embolism, Emilyz, Emmittthest, Emoo, Enki, Ennae, Enniskillen, Envyodb, Epel, Epicphail, Epona, Erastosthene, Ergaster, Erhid, Erinyesthegreat, Eroc, Ersitu, Eruriku, Esham, Esidrexua, Esolesek, Esowick, Ethario, Evadaly, Evarri, Evarya, Evening, Evilrevolutionary, Evilvision, Exania, Exar, Excudo, Eximus, Exletalis, Exmortis, Explora, Explosiver, Expression, Extraterrestrial, Eyerobot, Eyesgood, Eyywa",
      "Fabercrafting, Fabra, Fabricant, Faeldray, Fairyshine, Falkcibre, Falkirk, Farmerguy, Fatback, Fatboy, Fathertime, Fatynoob, Faud, Faziel, Fazz, Fearil, Febur, Felinas, Ferdiddicius, Ferryn, Festival, Fettuccinni, Feudalist, Fhinnion, Fhylli, Fiddlestick, Fight, Fillyblunts, Final, Finalwolfblack, Finn, Finndar, Finneh, Finnishptk, Finnsalto, Firecatd, Firedragon, Fireflyb, Firefox, Firemedic, Fireriders, Firewalker, Fjalke, Flamoush, Flegmar, Flubb, Flutteringdryer, Fokkre, Fomar, Fooli, Forseti, Fotb, Foulchu, Foxton, Foxx, Fraskesa, Freddy, Freddykrueger, Freebird, Friedrichpsitalon, Fring, Frivolous, Friyanouce, Froggeryz, Frontier, Frostforester, Frox, Fuegan, Fujiwara, Fulano, Function, Fundamental, Fushaum",
      "Gaeron, Gaffer, Galigan, Gamerdood, Gamurin, Ganson, Garath, Garf, Garfius, Garrasion, Garson, Gary, Gatwick, Gaustyrr, Gavin, Geminijulz, Geraldine, Geralt, Gerboa, Ghathern, Ghostofdeath, Ghostzephyr, Gibbs, Giblet, Giganthor, Gilchrist, Gillie, Gilwenaistrinas, Gis, Glamour, Gloosecap, Glory, Gobo, Goddessotu, Godoffal, Godrobotswife, Godzildarella, Gofs, Gog, Gogosh, Goingrey, Golarus, Goldfinch, Golkar, Gomeo, Gonzopl, Goofer, Gooseberry, Gorok, Gothrek, Greasygrundle, Greender, Greenthumbs, Greyax, Greyhound, Greypowerva, Griffith, Grifo, Grimpond, Griphyth, Gromdehar, Grotesque, Groukin, Grumblykins, Grumpyoldgamer, Gullgotha, Gumbert, Gumbo, Gunnbjorn, Guruen, Guvnor, Gwyn, Gyrinus",
      "Hacton, Hailiah, Halidor, Hallowyn, Hallucitania, Handsomestranger, Hankrearden, Hannaliese, Harth, Hashirama, Haslador, Hatoya, Hauler, Hawmpfish, Hazelspider, Hdptrequalsnul, Hektor, Helgaiden, Hellfang, Hellwyn, Heng, Henryoldfellow, Herald, Herbet, Hercules, Herding, Herme, Herrfritz, Hidden, Hilfe, Hillgrinder, Hiro, Hiroi, Hisoka, Hogwash, Holypower, Homemade, Hoppy, Horny, Horton, Hrig, Hrorvaig, Huey, Hulagirl, Huntarded, Hurdygurdyman, Huser, Huskyjnr, Hvergi, Hydropano, Hylander",
      "Iblees, Icabod, Icenrne, Icetrias, Ifix, Iggary, Ignimmeryus, Illusion, Impala, Indica, Indigoblue, Indmaj, Infarct, Intj, Inyan, Iota, Irbiska, Irtehwinner, Isis, Isleana, Isoki, Itsy, Izmanie, Izzyizzyizzy",
      "Jackbeau, Jackofharts, Jackoneill, Jacqulina, Jaded, Jagger, Jaindoe, Jakeii, Jakerivers, Jamel, Jamesgrim, Jameshunter, Jantheconqueror, Januszram, Jasiek, Jasonian, Jatagan, Jaybone, Jaydmg, Jaytee, Jaz, Jazzhammer, Jberg, Jeadgher, Jenks, Jennykellon, Jerryc, Jethrotall, Jhessa, Jiachi, Jibberish, Jikoo, Jilynn, Jimbob, Jive, Job, Jodie, Joedirttwo, Joedobo, Johindar, Johndecuire, Johnkeats, Johnnyash, Johnnylogen, Johnnymcaawesome, Johnnystar, Johnston, Jokaire, Jola, Jonathon, Jonydowy, Jorma, Joshuacalve, Jospicy, Jothebard, Jotz, Journeya, Joykill, Jukke, Juqni",
      "Kaaia, Kabill, Kace, Kaeyix, Kageoni, Kagetenshi, Kagroni, Kaide, Kaihmr, Kain, Kaitlin, Kaldari, Kaldros, Kamikazifly, Kamill, Kananiel, Kangee, Karkas, Karlton, Kassadin, Kastner, Kasumi, Katahl, Katatonia, Katella, Katherine, Kathleen, Katonya, Kawopij, Kazookazoo, Kazui, Kazzo, Keeperofthe, Keg, Kegan, Kelpy, Kenana, Kendle, Keneth, Kennyxest, Kensai, Kergan, Keuso, Keylala, Keyos, Kgorski, Khador, Khatanka, Khaylor, Khloey, Kichi, Kicks, Kidcurry, Kijani, Kilamplaga, Killroth, Kingdd, Kingdrew, Kingen, Kingfrog, Kingjared, Kiru, Kisabyakko, Kix, Kjoddleik, Klarseth, Klumzee, Knell, Kochinac, Kodos, Kojack, Koka, Koletar, Komragh, Komunalac, Konlin, Kooladekiller, Korchak, Koroth, Kraegar, Kraten, Kregor, Kriet, Kristoff, Kriswald, Krivak, Krokeksgardsmunken, Krylon, Kswords, Kulaan, Kuldim, Kunibert, Kurtsi, Kylebooze, Kyrina, Kyzersozay",
      "Ladygodiva, Ladyy, Lagston, Lailani, Laiwyn, Lambqxq, Lamu, Lanaya, Lancelot, Landgrave, Lando, Laqb, Lathe, Launcelot, Lbghost, Leaf, Ledz, Legionaire, Legios, Leilue, Lennoxx, Leonardo, Leonder, Levin, Leyna, Liasuny, Lift, Lightened, Lilbear, Lilbit, Lillabeaner, Lilnutrino, Lilychaos, Linaelia, Lindarah, Liquidangel, Liri, Lisabet, Littlemouse, Littlestorm, Livia, Lkilla, Locans, Lockehalfblood, Loetuw, Lokius, Loneshark, Lono, Looniel, Lordlouis, Lordterrabyte, Lordtyr, Lordxardus, Lordzmielot, Lorenrandal, Lorptahs, Losik, Loslung, Lostoffspring, Lothak, Lotr, Louhi, Louischalma, Ltloco, Lubkix, Lucasdid, Lucky, Lukenas, Lumbro, Lumi, Lunera, Lunn, Luscious, Lyft, Lyhrinae, Lyokan, Lyramis",
      "Macoofer, Madagascargot, Madamlola, Maddox, Madmann, Madnath, Madrigal, Magaric, Magicmike, Magnia, Magnir, Mahi, Majorminor, Makarus, Malack, Malak, Malcore, Malek, Malficus, Mallaboro, Malokai, Malvorn, Mamadarkness, Manfred, Mani, Manitu, Manthium, Mara, Margnor, Marienara, Marinara, Markcrow, Marksilard, Marlon, Marni, Martynas, Maryii, Masterentaro, Masterthief, Matteran, Maurizio, Maverickg, Mawej, Maximillian, Maximusthegreat, Maxxplor, Mayu, Mcwolfy, Megalodon, Meganoobcrafter, Meitai, Melimazar, Melloyello, Menachem, Merfin, Meria, Meridius, Metrix, Meyfei, Mhyth, Michelledent, Michiel, Middi, Mija, Mika, Mikaeli, Mikehunt, Miket, Mikhunter, Mikicus, Mikim, Milkdrop, Milksteak, Millsy, Miloch, Mimir, Mindar, Minerjoe, Miniroll, Minishane, Minobu, Mintberrycr, Miquela, Mishia, Mistyblue, Mitgira, Mith, Mitzie, Miyyaa, Mleko, Mobius, Mokii, Monarca, Monkeydo, Monokels, Montgomery, Moonbeam, Mordain, Mordstallon, Morghor, Morhedron, Morieta, Morkis, Morlee, Mornare, Moru, Morzews, Motasolo, Motega, Moumix, Moxie, Mrblades, Mrhook, Mrmanickk, Mrmorlanius, Mrrphil, Mrsmith, Mrtoobright, Mtvjet, Multianna, Musely, Muzk, Mystivia",
      "Naabs, Nacciwa, Nachtiti, Nadroj, Nafets, Najhocheese, Naksui, Nakunaru, Nalimar, Nanite, Nanook, Napoleonbonaparte, Narcox, Narlis, Natchtiti, Nathan, Nathanielsteele, Nattapong, Nattwo, Naulus, Ncorovaneer, Neanderthol, Neb, Nebtipenny, Necroedarkslayer, Necromany, Neenee, Negativenull, Neimo, Nekoras, Neoexplorer, Neolotus, Neome, Neovictus, Neowyn, Nest, Neteri, Neuronus, Nevinston, Nexacon, Niadara, Nicedreams, Nicksgranny, Nievette, Nightbreed, Nightfall, Niki, Nineol, Ninjarubberduck, Nipo, Nirav, Nirilen, Niryel, Nixienicola, Nocturnalist, Noffo, Noiattaq, Noiattaqq, Noizhead, Norad, Nordlys, Noss, Notstupidbobb, Nubbs, Nuin, Nukacola, Nulliusrex, Numaiky, Nussenknack, Nyalee, Nydos, Nyjull",
      "O, Oak, Oats, Obi, Object, Oblivionnreaver, Obsidianphoenix, Odynn, Offensivename, Olafhairybreeks, Oldtimer, Ona, Ondar, Onegigabyte, Oracle, Orchimed, Orcotot, Orlin, Orsin, Oshikia, Ostentatio, Othobrithol, Overdosed, Overlap, Owl",
      "Padremaronno, Paffski, Pagani, Palim, Palmiradeprez, Pandylynn, Pankivtanke, Panopticon, Papodoch, Para, Partrune, Pashka, Passepartout, Pathfinderr, Pattie, Paulneles, Payu, Pblack, Pedro, Pellandria, Pepejot, Percula, Perecles, Periwinkle, Permitheus, Perodin, Persephone, Petek, Pherik, Phoenixus, Phxreaper, Phynx, Pifa, Pigheaven, Pijavice, Pikakilei, Pimms, Pinchi, Pinejon, Pingpong, Pingvinen, Piperlane, Piru, Plaguejuice, Plater, Platypus, Plisscken, Plong, Polan, Polarbear, Polojordan, Pomeray, Popy, Poseidon, Postar, Potensdraco, Powers, Ppp, Pravy, Praxius, Pride, Primordia, Princessari, Professorcranky, Prom, Propheteer, Protunia, Pryath, Psychoticwolf, Puhhoney, Pulzifer, Puncher, Punnikin, Putzaroo, Pynkfloyd, Pyromander",
      "Qdlaty, Qiwi, Quasiwud, Queenrocks, Quelon, Quiglen, Qwiggalo",
      "Radiohead, Rafaello, Rage, Ragnvald, Raiderpl, Raidon, Raldor, Ralfthemouth, Ralis, Ram, Randnar, Rapidron, Rasheda, Rashunolaktor, Rastablanco, Rathalos, Ratmaster, Raulyn, Ravenclaw, Ravenfeather, Raybarg, Rayray, Rayw, Razgryz, Realcoffee, Reaping, Rebecca, Reddragon, Redhawk, Redi, Reegsman, Reflekted, Reflexx, Regkar, Rehd, Reincarnation, Rembrant, Remyth, Renewedpath, Renny, Restitution, Retanis, Retrograde, Retwer, Revelation, Revengo, Rexex, Rgr, Rhaegar, Rhianna, Rhiannone, Riceri, Richardcheese, Rifleredfish, Rightout, Riklaunim, Rillian, Ripshanks, Risky, Rivanko, Rivenwind, Riverboy, Riverwyle, Riverz, Rixum, Riyah, Rmt, Robak, Robolzer, Robus, Rocklobster, Rockybalboa, Rolf, Rollypolly, Roman, Romp, Ronal, Ronanxx, Ronsen, Ronya, Rosedragon, Rosie, Rothschild, Rottor, Rowin, Roysta, Rudie, Rudikus, Rumi, Run, Rusk, Rusma, Ryanross, Ryboodle, Rygar, Rylee",
      "Sabinfigaro, Sadmoon, Sadsack, Sagaras, Saigonangel, Saintdouglas, Sakai, Salacia, Salempl, Salexi, Salriel, Salvya, Salyer, Samtep, Samwithwiki, Sandyar, Sanfrid, Sankara, Sappiro, Sarahb, Sarai, Sarella, Saroman, Sateru, Satsugai, Savronne, Scalorn, Scaredface, Schiann, Schummys, Scooten, Scoutknife, Scratchy, Screwtape, Scxawng, Seajay, Seancarl, Sebonsakura, Sentientpeanutbutter, Seraphrattle, Seriphina, Serpentarius, Serronn, Seso, Setano, Setharius, Sevenseeker, Sgtscum, Shadowwolf, Shads, Shadyone, Shagsdeval, Shaidarhun, Shaka, Shamank, Shanderial, Sharkbait, Sharkin, Shatalkua, Shawncapalini, Shdorsh, Shenjiwurm, Shenra, Shenzo, Shevek, Shewolf, Shihan, Shindar, Shiny, Shione, Shocar, Shrimpiie, Shydow, Sidereal, Sidras, Siegfried, Sieman, Sigeroct, Silakka, Silas, Silencehero, Silverback, Silverdove, Silverrose, Silverwolfe, Silvirwolfe, Simyaci, Sin, Sintra, Siobhan, Siren, Sirfluffy, Siriusbanshee, Sirlaw, Sirmayhem, Sirmuttley, Sixiron, Sjach, Skannar, Skarra, Skarrtalin, Skateboard, Skoutt, Skren, Skybone, Skyefox, Slavya, Sleepys, Slickster, Slowmo, Smackeddown, Smada, Smelz, Smilingcat, Smokeyboy, Smoo, Snakegal, Snarkin, Sneg, Snoo, Snoopy, Solaren, Sollwick, Soluna, Somebodyx, Soraya, Soulflayar, Soulreaper, Spacezombie, Spacy, Spazzpriest, Speedokiller, Spiderman, Spynix, Spyte, Sqorpius, Squeezy, Squish, Stacia, Stagger, Stanlee, Stanleyclark, Starcrystal, Stargrace, Starr, Starshard, Starzwitch, Steelphoeni, Stephanus, Stevelee, Stinky, Stoffe, Stoneman, Stonesolid, Stormalong, Stormblade, Stormsquire, Strah, Strawberrie, Strawberrieluvsu, Strmesko, Strongestti, Stscum, Subie, Sugarfoxx, Sukime, Sukula, Summersnow, Sunnyside, Sunova, Sunstrike, Suntan, Susan, Susanna, Sushie, Swaen, Sweetsusie, Swerkell, Swingerpl, Swordfish, Syirian, Sylvatica, Syncaidius, Syndran, Synystar, Syrann",
      "Tabu, Taghorn, Takinshikaze, Takular, Taldeer, Taliosdenmark, Tamat, Tanner, Tano, Tarator, Tarikara, Tarlynnd, Tarnhold, Tarquin, Tashwelsh, Taxton, Taya, Tclunatic, Teallw, Tectyl, Tedzogh, Teeebomb, Teenie, Tegel, Tek, Tekada, Tekkata, Teledahn, Teloril, Telurius, Tenderwolf, Tendrik, Tenko, Ternesjach, Terryn, Teshack, Teslian, Testop, Tevan, Tgod, Thaddeus, Thaleia, ThallspringScout2, Thantosss, Thedesmon, Thedethroned, Theevercrow, Theflash, Thekraken, Thelastone, Theplague, Therealsennbad, Theresa, Therru, Theshadowmaster, Theshawv, Thesisus, Thetylerlee, Thewired, Thicket, Thijs, Thirtynine, Thistledragon, Thorgot, Thorinoakinshield, Threap, Thurii, Tiada, Tibothom, Tich, Tifa, Tigerfist, Tiki, Timeli, Timmehexas, Timothymarker, Tineen, Tit, Titanic, Tizzy, Todd, Toecutter, Toko, Tomatoes, Tombat, Tonhits, Topdog, Toradin, Torreto, Tpikol, Tracer, Trance, Tranquilizer, Translucent, Trenchfoot, Trentasaurus, Trevordent, Trevortheholy, Tris, Trithian, Tritus, Trololol, Tronn, Troopereau, Trucrow, Tsalib, Tstir, Tsubasalage, Tuanta, Tugekoka, Tukster, Turningjapanese, Twilight, Twistedmind, Tyberius, Tyveris, Tzia",
      "Udonne, Ulfred, Ultradude, Ulviirala, Unbelievable, Unknownorganism, Unruly, Urman, Ushtar, Usket, Uzair, Uzetaab",
      "Valeona, Valhalla, Valien, Valnar, Valodis, Valux, Vancarn, Vandet, Varcelli, Vardenlol, Vardoj, Varik, Vasco, Vasilysk, Vcboy, Veldez, Velmag, Velvetsun, Vero, Verotika, Vertigo, Vested, Vigii, Villager, Vincentruth, Vindikator, Vinius, Vion, Viremental, Virusmd, Visigo, Vogar, Void, Vooch, Voortman, Vortexx, Votip, Voxmortem, Vroomfondel, Vynja, Vyolette, Vyrna",
      "Waarokku, Waitfourherb, Waldbeschutzer, Walkinchubby, Waltdoh, Wargasm, Warhead, Warlander, Warlock, Warrior, Wauld, Weatherwax, Weedwarrior, Weirdojr, Welmari, Wendigo, Wesasaurus, Wexor, Weya, Whispper, Whiterose, Whykillme, Wicodech, Widnes, Wilca, Wildelf, Wildfire, Wilford, Wilit, Williamweird, Willieman, Willow, Willslaphandien, Windrune, Winniethepooh, Wintersolstice, Wiolo, Wirehead, Wishbone, Witchhazel, Witoc, Woad, Wolfbreed, Wolffles, Wolfgarr, Woodrow, Woodywurm, Wossoo, Wsrich, Wulfgar, Wulfgrymm, Wyczesanymieczyslaw, Wynd",
      "Xack, Xael, Xallo, Xandra, Xandrya, Xanthor, Xeneral, Xerx, Xhairy, Xilus, Xneo, Xristoff, Xvbabyvx, Xyla",
      "Yaga, Yagyujr, Yakyuk, Yasinkaraer, Yazonna, Yemoda, Yemodae, Yogibeartiberium, Yondu, Yoshigohonk, Yourshadowq, Yupps, Yuri",
      "Zachariah, Zaffasurf, Zahrkon, Zaka, Zakerak, Zaks, Zann, Zarame, Zarmazarmaex, Zeakgu, Zeldagold, Zenerith, Zenmaster, Zensunni, Zenwon, Zerobyteus, Zethreal, Zetuka, Zewizard, Ziem, Zien, Zigozag, Zigsa, Zihn, Zilbar, Zimgraham, Zivirt, Zkovacs, Zoranah, Zorky, Zorkyntha, Zorrent, Zpen, Zschaemeyers, Zukharie, Zuperman, Zurish, Zwald, Zzabr"
    ]    
  },
  getters: {
    getAllNames(state) {
      var newList = state.namesList
      for (let i=0; i < newList.length; i++) {
        newList[i] = newList[i].split(',')
      }
      return newList
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
