<template>
  <div class="maindiv">
    <div class="textsection">
      <img class="slightly-larger centered" src="../assets/RCMuseum.jpg" alt="front of museum light up with pink light">

      <h1>The Preservation Process</h1>
      <p>
        I, Nirav, have gathered items from all over the Southern servers including Chaos. Besides those, many things 
        were donated. Many of them had very partial signatures. In order to find signatures, if you don’t already know, 
        you imp the item, and each tens place, you get a different set of letters. When you examine an item, for 
        example, and the quality of that item is 20, you might see something like “You can barely make out the 
        signature of its maker, ‘.i…’. When it reaches quality 30, it might say something like “You can barely make 
        out the signature of its maker, ‘..ra.’. Now, you know that the signature is this, so far: ‘.ira.’ When you 
        get to 40, it might say “You can barely make out the signature of its maker, ‘N.r..’. At this point, at 40 ql, 
        you know 4 of 5 letters. If you use the signature finder here
        <a href="https://wurmfeed.rotabland.eu/signaturefreedom">https://wurmfeed.rotabland.eu/signaturefreedom</a> 
        and you type that in, or even just paste the examination from the event feed, the signature finder might have 
        one or more options for who that is. The signature finder is not always accurate, nor does it have all the names, 
        but it is one of the tools I used. I also have found the remaining last letter by typing /t and then the name, 
        putting each letter of the alphabet in until I find that last missing letter. If the name was viable, I would get 
        a text box to chat with that person. If not, I would get a message in the event tab that no one of that name 
        existed. If two or more letters worked, it was back to imping I went to find which was the accurate one for the 
        item in hand.
      </p> 
      <img class="standard-size centered" src="../assets/Statue made by Chabney.jpg" alt="showing that a statue was made by Chabney">
      <p>        
        I spent a lot of time on Niarja when that website was active, looking everyone up to make sure they were put 
        in the right place in the museum as to the PvE and PvP floors. This is subjective and often related more to where 
        I found the item originally (if I remembered, and if I acquired it that way). There is a lot of cross-over between
         PvE and PvP not only within the toons themselves who play in both areas, but because there are sales across that 
         boundary. Since the fall of the Niarja website, the PVP community has really stepped up to help me put items in the 
         correct kingdoms. It is inevitable there are mistakes, and if someone finds themselves on a floor or Kingdom they 
         would rather not be on, just send me a PM in the forum, and I will put the item where you wish it placed.
      </p>  
      <img class="standard-size centered" src="../assets/Cup by Enki.jpg" alt="cup by Enki">  &nbsp;    
      <p>
        Every item in the museum has been examined and cross-examined, and I am certain that the item is correctly 
        labeled in my renaming it.  Over time, those signatures will be lost.  Everyone who examines an item in the 
        museum runs the risk of erasing a signature. Unfortunately, there is nothing we can do about that at this time. 
        But If the item is in the museum and has been renamed stating who made it, it is an accurate statement. What you 
        can do to help with this is NOT examine the items.  They are all renamed so that you don’t have to. 
      </p>
      <p>
        There are doubles and triples and more of some names. That is because when I first made the museum, I didn’t 
        have as many, and was excited to find multiples by the same person. It is also because of where I started on Inde. 
        In this area, there were a lot of signatures of folks who affected the game in large ways, and in my early days, 
        felt Important to me, not only because of who they were, but because they were who they were Here in the same 
        space as the museum. To be fair, and transparent, it is also because I know and like some folks, so when I found 
        their items in the world, I was excited to preserve them. That favoritism has since faded in the face of this 
        project, but due to the sheer amount of Stuff here, I have not found and removed all of those extras.
      </p>
      <img class="standard-size centered" src="../assets/Items in Chest.jpg" alt="text seen when looking in a chest">
      <p>
        Disclaimer from the Curator – Neither I, nor Nirav, nor any of my alts, are to be held responsible for items that do get lost.  I will do everything in my power to avoid that, especially since all of you have put your trust in me. But the game is a game, and signatures will fade, and items may disintegrate even with me repairing everything as much as I can. Also, things could occur beyond my control regarding glitches or changes to rules that cause perms to get changed, and items to be stolen. I do not have the money to replace lost items.  Once an item is donated, it becomes the property of Rockcliff Museum, mayored by one of my alts, and you relinquish claim to the item. If you have put an item on loan, the item is yours, and only borrowed by the museum, but this disclaimer covers these as well. I will do everything in my power to keep all items safe – it is the entire mission of the Museum to do so, and the entire reason I created it. 
      </p>
     
      <!-- <img class="standard-size" src="../assets/Examined Items.jpg" alt="text shown when examining an item">  -->     
      <!-- <img class="standard-size" src="../assets/PvP floor.jpg" alt="view of the PVP floor"> -->
      <br> &nbsp; <br> 
     
      
      
      


    </div>
  </div>  
</template>


<script>
  export default {
    name: 'PreservationProcess'
  }
</script>


<style></style>